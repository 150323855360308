import {Card} from 'primereact/card';
import React, {useRef, useState} from 'react';
import DialogProfissional from '../loggedIn/common/dialogProfissional.component';
import {InputText} from 'primereact/inputtext';
import {classNames} from 'primereact/utils';
import {Button} from 'primereact/button';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Calendar} from 'primereact/calendar';
import comissaoService from '../../services/comissao.service';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';

const validationSchema = Yup.object().shape({
    profissional: Yup.object().required('Profissional é obrigatório.'),
    dataInicial: Yup.string().required('Data de Inicial é obrigatória.'),
    dataFinal: Yup.string().required('Data de Final é obrigatória.')
});

const ComissaoPesquisa = ({mostrarMensagem}) => {

    const [dialogProfissionalVisible, setDialogProfissionalVisible] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [comissoes, setComissoes] = useState([]);

    const formik = useFormik({
        initialValues: {
            id: '', profissional: null, profissionalId: null, dataInicio: null, horaInicial: null
        }, validationSchema, validate: (values) => {
            if (values.presencial && !values.sala) return {sala: 'Sala é obrigatória.'};
        }, onSubmit: (values) => {
            setLoadingSubmit(true);
            gerarComissao();
        }
    });

    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
    };

    const gerarComissao = () => {
        comissaoService.gerarComissao({
            profissionalId: formik.values.profissionalId,
            dataInicial: formik.values.dataInicial,
            dataFinal: formik.values.dataFinal
        }).then((result) => {

            let listaAtendimentos = [];

            result.atendimentos.forEach((atendimento) => {
                listaAtendimentos.push({
                    nomePaciente: atendimento.nomePaciente,
                    dataAtendimento: formatarData(atendimento.dataAtendimento),
                    sala: atendimento.sala,
                    horario: atendimento.horaInicial + ' - ' + atendimento.horaFinal,
                    valor: atendimento.valor,
                    comissaoPorcentagem: 20,
                    comissaoValor: atendimento.valor * 0.2
                });
            });

            setComissoes({
                nomeProfissional: result.nomeProfissional,
                dataInicial: formatarData(result.dataInicial),
                dataFinal: formatarData(result.dataFinal),
                valorTotal: converterValor(result.valorTotal),
                comissaoTotal: converterValor(result.comissaoTotal),
                atendimentos: listaAtendimentos
            });

            setLoadingSubmit(false);
        }).catch((error) => {
            mostrarMensagem(error.response.data, 'error', 'Erro', true);
            setLoadingSubmit(false);
        });
    };

    const formatarData = (data) => {
        const [ano, mes, dia] = data.split('T')[0].split('-');
        return `${dia}/${mes}/${ano}`;
    };

    const converterValor = (saldo) => {
        return ('R$ ' + saldo.toLocaleString('pt-br', {
            minimumFractionDigits: 2, maximumFractionDigits: 2
        }));
    };

    const dt = useRef(null);
    const cols = [{field: 'nomePaciente', header: 'Paciente'},
        {field: 'dataAtendimento', header: 'Data'},
        {field: 'sala', header: 'Sala'},
        {field: 'horario', header: 'Horário'},
        {field: 'valor', header: 'Valor'},
        {field: 'comissaoPorcentagem', header: 'Comissão %'},
        {field: 'comissaoValor', header: 'Comissão Valor',}];

    const exportColumns = cols.map((col) => ({title: col.header, dataKey: col.field}));

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, comissoes.atendimentos);
                doc.save('comissao' + comissoes.nomeProfissional + '_' + comissoes.dataInicial + '_' + comissoes.dataFinal + '.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {

            let atendimentos = [...comissoes.atendimentos];

            atendimentos.push({
                nomePaciente: 'Total',
                dataAtendimento: '',
                sala: '',
                horario: '',
                valor: '',
                comissaoPorcentagem: '',
                comissaoValor: ''
            });

            let worksheet = xlsx.utils.json_to_sheet(atendimentos);
            worksheet['A1'].v = 'Paciente';
            worksheet['B1'].v = 'Data';
            worksheet['C1'].v = 'Sala';
            worksheet['D1'].v = 'Horário';
            worksheet['E1'].v = 'Valor';
            worksheet['F1'].v = 'Comissão %';
            worksheet['G1'].v = 'Comissão Valor';

            for (let i = 2; i <= atendimentos.length; i++) {
                worksheet['G' + i].f = '=E' + i + '*F' + i + '%';
            }
            worksheet['E' + (atendimentos.length + 1)].f = '=SUM(E2:E' + atendimentos.length + ')';
            worksheet['G' + (atendimentos.length + 1)].f = '=SUM(G2:G' + atendimentos.length + ')';

            for (let i = 2; i <= atendimentos.length + 1; i++) {
                worksheet['E' + i].z = '#,##0.00';
                worksheet['G' + i].z = '#,##0.00';
            }

            const wscols = [{wch: 25}, {wch: 10}, {wch: 15}, {wch: 12}, {wch: 12}, {wch: 12}, {wch: 12}];
            worksheet['!cols'] = wscols;

            worksheet['!rows'] = [{
                hpt: 20, hpx: 20,
            }];


            const workbook = {Sheets: {data: worksheet}, SheetNames: ['data']};
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx', type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'comissao_' + comissoes.nomeProfissional);
        });
    };

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    return (<>
        <Card title="Gerar Comissão">
            <div className="formgrid grid">
                <div className="field col-12">
                    <label htmlFor="profissional">Profissional</label>
                    <div className="flex" onClick={() => setDialogProfissionalVisible(true)}>
                        <InputText
                            value={formik.values.profissional?.nome}
                            className={classNames({
                                'p-invalid': isFormFieldInvalid('profissional')
                            }, 'w-full flex-grow-1 mr-2')}
                            style={{cursor: 'pointer'}}
                            readOnly
                        />
                        <Button icon="pi pi-search" className="flex-none"/>
                    </div>
                    {getFormErrorMessage('profissional')}
                </div>
                <div className="field col-12">
                    <label htmlFor="dataAgendamento">Data Inicial</label>
                    <Calendar
                        id="dataAgendamento"
                        value={formik.values.dataInicial}
                        onChange={(e) => formik.setFieldValue('dataInicial', e.value)}
                        dateFormat="dd/mm/yy"
                        className={classNames({
                            'p-invalid': isFormFieldInvalid('dataInicial')
                        }, 'w-full')}
                        showIcon
                        touchUI={window.innerWidth < 768}
                        showOnFocus={false}
                        mask="99/99/9999"
                    />
                    {getFormErrorMessage('dataInicial')}
                </div>
                <div className="field col-12">
                    <label htmlFor="dataFinal">Data Final</label>
                    <Calendar
                        id="dataFinal"
                        value={formik.values.dataFinal}
                        onChange={(e) => formik.setFieldValue('dataFinal', e.value)}
                        dateFormat="dd/mm/yy"
                        className={classNames({
                            'p-invalid': isFormFieldInvalid('dataFinal')
                        }, 'w-full')}
                        showIcon
                        touchUI={window.innerWidth < 768}
                        showOnFocus={false}
                        mask="99/99/9999"
                    />
                    {getFormErrorMessage('dataFinal')}
                </div>
            </div>

            <div className="buttons mt-2">
                <Button
                    label="Gerar Comissão"
                    icon="pi pi-save"
                    severity="success"
                    onClick={() => formik.handleSubmit()}
                    loading={loadingSubmit}
                    type="submit"
                />
            </div>
        </Card>

        <Card title="Comissões" className={'mt-2'} hidden={!comissoes.atendimentos}>

            <h2>{comissoes.nomeProfissional}</h2>
            <h2>{comissoes.dataInicial} - {comissoes.dataFinal}</h2>
            <h2>Valor Total: {comissoes.valorTotal}</h2>
            <h2>Comissão Total: {comissoes.comissaoTotal}</h2>

            <DataTable value={comissoes.atendimentos} className="p-datatable-sm">
                <Column field="nomePaciente" header="Paciente"/>
                <Column field="dataAtendimento" header="Data"/>
                <Column field="sala" header="Sala"/>
                <Column field="horario" header="Horário"/>
                <Column body={(field) => {
                    return converterValor(field.valor);
                }} header="Valor"/>
                <Column field="comissaoPorcentagem" header="Comissão %"/>
                <Column body={(field) => {
                    return converterValor(field.comissaoValor);
                }} header="Comissão Valor"/>
            </DataTable>

            <div className={'buttons mt-2'}>
                <Button type="button" icon="pi pi-file-excel" severity="success" rounded onClick={exportExcel}
                        data-pr-tooltip="XLS"/>
                <Button type="button" icon="pi pi-file-pdf" severity="warning" rounded onClick={exportPdf}
                        data-pr-tooltip="PDF"/>
            </div>
        </Card>


        <DialogProfissional
            dialogProfissionalVisible={dialogProfissionalVisible}
            setDialogProfissionalVisible={setDialogProfissionalVisible}
            onSelectMetodo={(profissional) => {
                formik.setFieldValue('profissional', profissional);
                formik.setFieldValue('profissionalId', profissional.id);
            }}
        />
    </>);
};

export default ComissaoPesquisa;
