import authService from "../../../services/auth.service";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";

import { useFormik } from "formik";
import { useMountEffect } from "primereact/hooks";
import { classNames } from "primereact/utils";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  senhaAntiga: Yup.string().required("Senha antiga é obrigatória."),
  senhaNova: Yup.string().required("Senha nova é obrigatória.").min(6, "Senha deve ter no mínimo 6 caracteres."),
  senhaNovaConfirmacao: Yup.string()
    .required("Confirmação de senha nova é obrigatória.")
    .equals([Yup.ref("senhaNova")], "Senhas não conferem."),
});

const DialogSenha = ({ dialogSenhaVisible, setDialogSenhaVisible }) => {
  useMountEffect(() => {
    formik.setFieldValue("senhaAntiga", "");
    formik.setFieldValue("senhaNova", "");
    formik.setFieldValue("senhaNovaConfirmacao", "");
  }, []);

  const formik = useFormik({
    initialValues: {
      senhaAntiga: "",
      senhaNova: "",
      senhaNovaConfirmacao: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (values.senhaNova !== values.senhaNovaConfirmacao) {
        alert("Senhas não conferem");
        resetarForm();
        return;
      }

      authService
        .alterarSenha(values)
        .then((result) => {
          setDialogSenhaVisible(false);
          resetarForm();
          alert("Senha alterada com sucesso!");
        })
        .catch(() => alert("Senha antiga incorreta! Não foi possivel alterar a senha!"));
    },
  });

  const resetarForm = () => {
    formik.resetForm();
    formik.setValues({
      senhaAntiga: "",
      senhaNova: "",
      senhaNovaConfirmacao: "",
    });
  };

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name, formik) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  return (
    <>
      <Dialog
        header="Alteração de Senha"
        visible={dialogSenhaVisible}
        onHide={() => {
          setDialogSenhaVisible(false);
          resetarForm();
        }}
        onShow={() => resetarForm()}
        style={{ width: "90vw" }}
      >
        <>
          <div className="formgrid grid">
            <div className="field col-12">
              <label htmlFor="senhaAntiga">Senha Antiga</label>
              <InputText
                id="senhaAntiga"
                value={formik.values.senhaAntiga}
                maxLength={50}
                onChange={(e) => formik.setFieldValue("senhaAntiga", e.target.value)}
                className={classNames({ "p-invalid": isFormFieldInvalid("senhaAntiga") }, "w-full")}
                type="password"
              />
              {getFormErrorMessage("senhaAntiga")}
            </div>
            <div className="field col-12">
              <label htmlFor="senhaNova">Senha Nova</label>
              <InputText
                id="senhaNova"
                value={formik.values.senhaNova}
                maxLength={50}
                onChange={(e) => formik.setFieldValue("senhaNova", e.target.value)}
                className={classNames({ "p-invalid": isFormFieldInvalid("senhaNova") }, "w-full")}
                type="password"
              />
              {getFormErrorMessage("senhaNova")}
            </div>
            <div className="field col-12">
              <label htmlFor="senhaNovaConfirmacao">Confirmação de Senha Nova</label>
              <InputText
                id="senhaNovaConfirmacao"
                value={formik.values.senhaNovaConfirmacao}
                maxLength={50}
                onChange={(e) => formik.setFieldValue("senhaNovaConfirmacao", e.target.value)}
                className={classNames({ "p-invalid": isFormFieldInvalid("senhaNovaConfirmacao") }, "w-full")}
                type="password"
              />
              {getFormErrorMessage("senhaNovaConfirmacao")}
            </div>
          </div>
          <div className="buttons mt-2">
            <Button label="Alterar" icon="pi pi-save" severity="success" onClick={() => formik.handleSubmit()} type="submit" />
            <Button label="Cancelar" icon="pi pi-times" severity="secondary" onClick={() => setDialogSenhaVisible(false)} />
          </div>
        </>
      </Dialog>
    </>
  );
};

export default DialogSenha;
