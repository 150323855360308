import React from "react";

import { Card } from "primereact/card";
import { Skeleton } from "primereact/skeleton";

const EdicaoMovimentacaoLoading = () => {
  return (
    <Card
      title={() => {
        return <Skeleton height="45px" width="400px" />;
      }}
    >
      <div className="formgrid grid">
        <div className="field col-12 lg:col-8">
          <Skeleton height="15px" width="60px" className="mb-1" />
          <Skeleton height="35px" />
        </div>
        <div className="field col-12 lg:col-4 md:col-6">
          <Skeleton height="15px" width="60px" className="mb-1" />
          <Skeleton height="35px" />
        </div>
        <div className="field col-12 lg:col-4 md:col-6">
          <Skeleton height="15px" width="60px" className="mb-1" />
          <Skeleton height="35px" />
        </div>
        <div className="field col-12 lg:col-4 md:col-6">
          <Skeleton height="15px" width="60px" className="mb-1" />
          <Skeleton height="35px" />
        </div>

        <div className="field col-12 lg:col-4 md:col-6">
          <Skeleton height="15px" width="60px" className="mb-1" />
          <Skeleton height="35px" />
        </div>
        <div className="field col-12">
          <Skeleton height="15px" width="60px" className="mb-1" />
          <Skeleton height="35px" />
        </div>
      </div>
    </Card>
  );
};

export default EdicaoMovimentacaoLoading;
