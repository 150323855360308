import axiosInstance from "./baseService.service";

const API_URL = "comissao/";

const comissaoService = {

  gerarComissao: async (params) => {
    try {
      const response = await axiosInstance.post(API_URL + "gerarComissao", params);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
};

export default comissaoService;
