import apoioService from "../../../services/apoio.service";
import authService from "../../../services/auth.service";
import pacienteService from "../../../services/paciente.service";
import Yup from "../../../util/CustomYup";

import { formatarData } from "../../../util/FormatarData";

import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { useMountEffect } from "primereact/hooks";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { TabPanel, TabView } from "primereact/tabview";
import { classNames } from "primereact/utils";
import React, { useState } from "react";
import TabResponsavel from "./tabResponsavel.component";

const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Campo obrigatório."),
  email: Yup.string()
    .matches("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$", "Email inválido. (Ex: email@email.com)")
    .nullable(true),
});

const PacienteEdicao = ({ mostrarMensagem }) => {
  const permissaoCadastroPaciente = authService.checkIfHasPermissao(["CAD_PACIENTE"]);
  const permissaoPesquisaResponsavel = authService.checkIfHasPermissao(["PES_RESPONSAVEL"]);

  const [paciente, setPaciente] = useState({});
  const [tipoSexo, setTipoSexo] = useState([]);
  const [tipoEstadoCivil, setTipoEstadoCivil] = useState([]);
  const [tipoEscolaridade, setTipoEscolaridade] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const formik = useFormik({
    initialValues: {
      id: 0,
      nome: "",
      cpf: undefined,
      rg: undefined,
      dataNascimento: "",
      sexo: undefined,
      sexoId: undefined,
      estadoCivil: undefined,
      estadoCivilId: undefined,
      profissao: undefined,
      religiao: undefined,
      escolaridade: undefined,
      escolaridadeId: undefined,
      telefone: undefined,
      email: undefined,
      endereco: {
        rua: undefined,
        numero: undefined,
        complemento: undefined,
        bairro: undefined,
        cidade: undefined,
        estado: undefined,
        cep: undefined,
      },
      responsaveis: [],
      queixaPrincipal: undefined,
      sintomas: undefined,
      possiveisCausas: undefined,
    },
    validationSchema,
    onSubmit: (values) => {
      if (values.sexo) values.sexoId = values.sexo.id;
      if (values.estadoCivil) values.estadoCivilId = values.estadoCivil.id;
      if (values.escolaridade) values.escolaridadeId = values.escolaridade.id;
      setLoadingSubmit(true);
      if (values.id !== 0) {
        pacienteService
          .editPaciente(values)
          .then((result) => {
            mostrarMensagem("Paciente alterado com sucesso!", "success");
            setLoadingSubmit(false);
            setPaciente(values);
          })
          .catch((error) => {
            setLoadingSubmit(false);
            if (error !== undefined) {
              mostrarMensagem(error, "error");
              if (error instanceof Array && error.includes("CPF já cadastrado."))
                formik.setFieldError("cpf", "CPF já cadastrado.");
            } else {
              mostrarMensagem("Não foi possivel alterar o Paciente!", "error");
            }
          });
      } else {
        pacienteService
          .createPaciente(values)
          .then((result) => {
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("id", result);
            searchParams.set("criado", true);
            window.location.search = searchParams;
            setLoadingSubmit(false);
          })
          .catch((error) => {
            setLoadingSubmit(false);
            if (error !== undefined) {
              mostrarMensagem(error, "error");
              if (error instanceof Array && error.includes("CPF já cadastrado."))
                formik.setFieldError("cpf", "CPF já cadastrado.");
            } else {
              mostrarMensagem("Não foi possivel cadastrar o Paciente!", "error");
            }
          });
      }
    },
  });

  useMountEffect(() => {
    apoioService.listaTipoSexo().then((result) => setTipoSexo(result));
    apoioService.listaTipoEstadoCivil().then((result) => setTipoEstadoCivil(result));
    apoioService.listaTipoEscolaridade().then((result) => setTipoEscolaridade(result));

    const searchParams = new URLSearchParams(window.location.search);
    const idParam = searchParams.get("id");
    if (!idParam) {
      setActiveIndex(1);
      return;
    }

    if (searchParams.has("criado")) mostrarMensagem("Paciente cadastrado com sucesso!", "success");

    if (idParam !== 0 && idParam !== null) {
      pacienteService
        .getPaciente(idParam)
        .then((result) => {
          if (result.status === 200) {
            const data = result.data;
            formik.setValues(data);
            setPaciente(data);
          }
        })
        .catch((error) => {
          mostrarMensagem("Não foi encontrado nenhum Paciente com o ID: " + idParam, "error");
        });
    }
  }, [mostrarMensagem, formik]);

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  return (
    <>
      <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
        <TabPanel header="Principal" disabled={!paciente.id}>
          <div className="formgrid grid">
            <div className="field col-12 md:col-8">
              <label htmlFor="nome">Nome</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{paciente.nome}</span>
              </div>
            </div>
            <div className="field col-12 lg:col-4 md:col-4">
              <label htmlFor="dataNascimento">Data de Nascimento</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  {paciente.dataNascimento ? formatarData(paciente.dataNascimento) : "-"}
                </span>
              </div>
            </div>
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="sexo">Sexo</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{paciente.sexo ? paciente.sexo.descricao : "-"}</span>
              </div>
            </div>
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="estadoCivil">Estado Civil</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  {paciente.estadoCivil ? paciente.estadoCivil.descricao : "-"}
                </span>
              </div>
            </div>
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="profissao">Profissão</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{paciente.profissao ? paciente.profissao : "-"}</span>
              </div>
            </div>
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="escolaridade">Escolaridade</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  {paciente.escolaridade ? paciente.escolaridade.descricao : "-"}
                </span>
              </div>
            </div>

            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="cpf">CPF</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{paciente.cpf ? paciente.cpf : "-"}</span>
              </div>
            </div>
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="rg">RG</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{paciente.rg ? paciente.rg : "-"}</span>
              </div>
            </div>

            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="telefone">Telefone</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{paciente.telefone ? paciente.telefone : "-"}</span>
              </div>
            </div>
            <div className="field col-12 lg:col-3 md:col-8">
              <label htmlFor="email">Email</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>{paciente.email ? paciente.email : "-"}</span>
              </div>
            </div>
          </div>
          <Divider />
          <div className="mb-2">
            <span style={{ fontSize: "1.2rem", fontWeight: "700", marginBottom: "0.5rem" }}>Endereço</span>
          </div>
          <div className="formgrid grid">
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="cep">CEP</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  {paciente.endereco && paciente.endereco.cep ? paciente.endereco.cep : "-"}
                </span>
              </div>
            </div>
            <div className="field col-12 lg:col-9 md:col-8">
              <label htmlFor="rua">Rua</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  {paciente.endereco && paciente.endereco.rua ? paciente.endereco.rua : "-"}
                </span>
              </div>
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="numero">Número</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  {paciente.endereco && paciente.endereco.numero ? paciente.endereco.numero : "-"}
                </span>
              </div>
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="bairro">Bairro</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  {paciente.endereco && paciente.endereco.bairro ? paciente.endereco.bairro : "-"}
                </span>
              </div>
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="cidade">Cidade</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  {paciente.endereco && paciente.endereco.cidade ? paciente.endereco.cidade : "-"}
                </span>
              </div>
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="estado">Estado</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  {paciente.endereco && paciente.endereco.estado ? paciente.endereco.estado : "-"}
                </span>
              </div>
            </div>
            <div className="field col-12">
              <label htmlFor="complemento">Complemento</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                  {paciente.endereco && paciente.endereco.complemento ? paciente.endereco.complemento : "-"}
                </span>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel header="Cadastro" disabled={!permissaoCadastroPaciente}>
          <div className="mb-2">
            <span style={{ fontSize: "1.2rem", fontWeight: "700", marginBottom: "0.5rem" }}>Dados Principais</span>
          </div>
          <div className="formgrid grid">
            <div className="field col-12 md:col-8">
              <label htmlFor="nome">Nome</label>
              <InputText
                id="nome"
                value={formik.values.nome}
                maxLength={150}
                onChange={(e) => formik.setFieldValue("nome", e.target.value)}
                className={classNames({ "p-invalid": isFormFieldInvalid("nome") }, "w-full")}
                disabled={!permissaoCadastroPaciente}
              />
              {getFormErrorMessage("nome")}
            </div>
            <div className="field col-12 lg:col-4 md:col-4">
              <label htmlFor="dataNascimento">Data de Nascimento</label>
              <Calendar
                id="dataNascimento"
                value={formik.values.dataNascimento !== null ? new Date(formik.values.dataNascimento) : null}
                onChange={(e) => {
                  if (e.target.value === "") e.target.value = null;
                  if (e.target.value !== null) {
                    formik.setFieldValue("dataNascimento", new Date(e.value).toISOString());
                  } else {
                    formik.setFieldValue("dataNascimento", null);
                  }
                }}
                showIcon
                className={classNames({ "p-invalid": isFormFieldInvalid("dataNascimento") }, "w-full")}
                disabled={!permissaoCadastroPaciente}
                touchUI={window.innerWidth < 768}
                showOnFocus={false}
                mask="99/99/9999"
              />
              {getFormErrorMessage("dataNascimento")}
            </div>
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="sexo">Sexo</label>
              <Dropdown
                id="sexo"
                value={formik.values.sexo}
                onChange={(e) => formik.setFieldValue("sexo", e.target.value)}
                options={tipoSexo}
                optionLabel="descricao"
                placeholder="Selecione o sexo"
                className={classNames({ "p-invalid": isFormFieldInvalid("sexo") }, "w-full")}
                disabled={!permissaoCadastroPaciente}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="estadoCivil">Estado Civil</label>
              <Dropdown
                id="estadoCivil"
                value={formik.values.estadoCivil}
                onChange={(e) => formik.setFieldValue("estadoCivil", e.target.value)}
                options={tipoEstadoCivil}
                optionLabel="descricao"
                placeholder="Selecione o estado civil"
                className={classNames({ "p-invalid": isFormFieldInvalid("estadoCivil") }, "w-full")}
                disabled={!permissaoCadastroPaciente}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="profissao">Profissão</label>
              <InputText
                id="profissao"
                value={formik.values.profissao}
                maxLength={150}
                onChange={(e) => formik.setFieldValue("profissao", e.target.value)}
                className={classNames({ "p-invalid": isFormFieldInvalid("profissao") }, "w-full")}
                disabled={!permissaoCadastroPaciente}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="escolaridade">Escolaridade</label>
              <Dropdown
                id="escolaridade"
                value={formik.values.escolaridade}
                onChange={(e) => formik.setFieldValue("escolaridade", e.target.value)}
                options={tipoEscolaridade}
                optionLabel="descricao"
                placeholder="Selecione a escolaridade"
                className={classNames({ "p-invalid": isFormFieldInvalid("escolaridade") }, "w-full")}
                disabled={!permissaoCadastroPaciente}
              />
            </div>

            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="cpf">CPF</label>
              <InputMask
                id="cpf"
                value={formik.values.cpf}
                onChange={(e) => {
                  if (e.target.value === "") e.target.value = null;
                  formik.setFieldValue("cpf", e.target.value);
                }}
                mask="999.999.999-99"
                placeholder="999.999.999-99"
                className={classNames({ "p-invalid": isFormFieldInvalid("cpf") }, "w-full")}
                disabled={!permissaoCadastroPaciente}
              />
              {getFormErrorMessage("cpf")}
            </div>
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="rg">RG</label>
              <InputMask
                id="rg"
                value={formik.values.rg}
                onChange={(e) => {
                  if (e.target.value === "") e.target.value = null;
                  formik.setFieldValue("rg", e.target.value);
                }}
                mask="99.999.999-*"
                placeholder="99.999.999-9"
                className="w-full"
                disabled={!permissaoCadastroPaciente}
              />
            </div>

            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="telefone">Telefone</label>
              <InputMask
                id="telefone"
                value={formik.values.telefone}
                onChange={(e) => {
                  if (e.target.value === "") e.target.value = null;
                  formik.setFieldValue("telefone", e.target.value);
                }}
                mask="(99) 99999-9999"
                placeholder="(99) 99999-9999"
                className={classNames({ "p-invalid": isFormFieldInvalid("telefone") }, "w-full")}
                disabled={!permissaoCadastroPaciente}
              />
              {getFormErrorMessage("telefone")}
            </div>
            <div className="field col-12 lg:col-3 md:col-8">
              <label htmlFor="email">Email</label>
              <InputText
                id="email"
                value={formik.values.email}
                maxLength={150}
                onChange={(e) => formik.setFieldValue("email", e.target.value)}
                className={classNames({ "p-invalid": isFormFieldInvalid("email") }, "w-full")}
                disabled={!permissaoCadastroPaciente}
              />
              {getFormErrorMessage("email")}
            </div>
          </div>
          <Divider />
          <div className="mb-2">
            <span style={{ fontSize: "1.2rem", fontWeight: "700", marginBottom: "0.5rem" }}>Endereço</span>
          </div>
          <div className="formgrid grid">
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="cep">CEP</label>
              <InputMask
                id="cep"
                className="w-full"
                value={formik.values.endereco?.cep}
                onChange={(e) => {
                  if (e.target.value === "") e.target.value = null;
                  formik.setFieldValue("endereco.cep", e.target.value);
                }}
                mask="99999-999"
                placeholder="99999-999"
                disabled={!permissaoCadastroPaciente}
              />
            </div>
            <div className="field col-12 lg:col-9 md:col-8">
              <label htmlFor="rua">Rua</label>
              <InputText
                id="rua"
                className="w-full"
                value={formik.values.endereco?.rua}
                maxLength={150}
                onChange={(e) => formik.setFieldValue("endereco.rua", e.target.value)}
                disabled={!permissaoCadastroPaciente}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="numero">Número</label>
              <InputText
                id="numero"
                className="w-full"
                value={formik.values.endereco?.numero}
                maxLength={50}
                onChange={(e) => formik.setFieldValue("endereco.numero", e.target.value)}
                disabled={!permissaoCadastroPaciente}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="bairro">Bairro</label>
              <InputText
                id="bairro"
                className="w-full"
                value={formik.values.endereco?.bairro}
                maxLength={100}
                onChange={(e) => formik.setFieldValue("endereco.bairro", e.target.value)}
                disabled={!permissaoCadastroPaciente}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="cidade">Cidade</label>
              <InputText
                id="cidade"
                className="w-full"
                value={formik.values.endereco?.cidade}
                maxLength={100}
                onChange={(e) => formik.setFieldValue("endereco.cidade", e.target.value)}
                disabled={!permissaoCadastroPaciente}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="estado">Estado</label>
              <InputText
                id="estado"
                className="w-full"
                value={formik.values.endereco?.estado}
                maxLength={100}
                onChange={(e) => formik.setFieldValue("endereco.estado", e.target.value)}
                disabled={!permissaoCadastroPaciente}
              />
            </div>
            <div className="field col-12">
              <label htmlFor="complemento">Complemento</label>
              <InputTextarea
                id="complemento"
                className="w-full"
                value={formik.values.endereco?.complemento}
                maxLength={255}
                onChange={(e) => formik.setFieldValue("endereco.complemento", e.target.value)}
                disabled={!permissaoCadastroPaciente}
                rows={1}
                cols={30}
                autoResize
              />
            </div>
          </div>
          <Divider />
          <div className="buttons">
            <Button
              label="Salvar"
              icon="pi pi-save"
              severity="success"
              type="submit"
              onClick={() => {
                formik.handleSubmit();
              }}
              loading={loadingSubmit}
            />
          </div>
        </TabPanel>
        <TabPanel header="Responsável" disabled={!paciente.id || !permissaoPesquisaResponsavel}>
          <TabResponsavel pacienteId={paciente.id} mostrarMensagem={mostrarMensagem} />
        </TabPanel>
      </TabView>
    </>
  );
};

export default PacienteEdicao;
