import authService from "../../../services/auth.service";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useMountEffect } from "primereact/hooks";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import Yup from "../../../util/CustomYup";

const validationSchema = Yup.object().shape({
  senhaNova: Yup.string().required("Senha nova é obrigatória.").min(6, "Senha deve ter no mínimo 6 caracteres."),
  senhaNovaConfirmacao: Yup.string()
    .required("Confirmação de senha nova é obrigatória.")
    .equals([Yup.ref("senhaNova")], "Senhas não conferem."),
});

const AlterarSenhaToken = ({ mostrarMensagem }) => {
  const [loading, setLoading] = useState(false);

  useMountEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!searchParams.has("token")) {
      mostrarMensagem("Token não encontrado", "error");
      window.location.href = "/login";
    }
  });

  const formik = useFormik({
    initialValues: {
      email: null,
    },
    validationSchema,
    onSubmit: (values) => {
      if (values.senhaNova !== values.senhaNovaConfirmacao) {
        mostrarMensagem("As senhas não conferem", "error");
        return;
      }

      setLoading(true);
      const searchParams = new URLSearchParams(window.location.search);
      authService
        .alterarSenhaToken(values.senhaNova, searchParams.get("token"))
        .then(() => {
          mostrarMensagem("Senha alterada com sucesso!", "success");
          setLoading(false);
          window.location.href = "/login";
        })
        .catch((error) => {
          mostrarMensagem(error, "error");
          setLoading(false);
        });
    },
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  return (
    <>
      <Card title="Redefinição de Senha">
        <div className="formgrid grid">
          <div className="field col-12">
            <label htmlFor="senhaNova">Nova Senha</label>
            <InputText
              id="senhaNova"
              name="senhaNova"
              value={formik.values.senhaNova}
              onChange={(e) => formik.setFieldValue("senhaNova", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("senhaNova") }, "w-full")}
              disabled={loading}
              type="password"
              maxLength={50}
            />
            {getFormErrorMessage("senhaNova")}
          </div>
          <div className="field col-12">
            <label htmlFor="senhaNovaConfirmacao">Repita a Nova Senha</label>
            <InputText
              id="senhaNovaConfirmacao"
              name="senhaNovaConfirmacao"
              value={formik.values.senhaNovaConfirmacao}
              onChange={(e) => formik.setFieldValue("senhaNovaConfirmacao", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("senhaNovaConfirmacao") }, "w-full")}
              disabled={loading}
              type="password"
              maxLength={50}
            />
            {getFormErrorMessage("senhaNovaConfirmacao")}
          </div>
        </div>
        <div className="buttons">
          <Button
            label="Redefinir Senha"
            icon="pi pi-save"
            severity="success"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
            loading={loading}
          />
        </div>
      </Card>
    </>
  );
};

export default AlterarSenhaToken;
