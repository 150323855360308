import * as Yup from "yup";
import { ValidarCPF } from "./Validador";

Yup.addMethod(Yup.string, "validarCPF", function (errorMessage) {
  return this.test("validarCPF", errorMessage, function (value) {
    if (value === undefined || value === null || value === "") return true;
    return ValidarCPF(value);
  });
});

export default Yup;
