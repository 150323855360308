import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";

import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { classNames } from "primereact/utils";
import authService from "../../../services/auth.service";
import { InputText } from "primereact/inputtext";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import DialogSala from "../common/dialogSala.component";
import { InputNumber } from "primereact/inputnumber";

const DialogAgendamentoAlterar = ({ dialogVisible, setDialogVisible, agendamento, onSaveMetodo }) => {
  const permissaoPesquisaSala = authService.checkIfHasPermissao(["PES_SALA"]);
  const [dialogSalaVisible, setDialogSalaVisible] = useState(false);

  const [diffTime, setDiffTime] = useState({ diffHour: 1, diffMin: 0 });

  const formik = useFormik({
    initialValues: {
      id: agendamento.id,
      paciente: { id: agendamento.pacienteId, nome: agendamento.pacienteNome },
      profissional: { id: agendamento.profissionalId, nome: agendamento.profissionalNome },
      dataAgendamento: new Date(agendamento.dataAgendamento),
      horaInicial: agendamento.horaInicial,
      horaFinal: agendamento.horaFinal,
      sala: { id: agendamento.salaId, nome: agendamento.salaNome },
      salaId: agendamento.salaId,
      valor: agendamento.valor,
      presencial: agendamento.presencial,
    },
    validate: (values) => {
      let errors = {};
      if (!values.dataAgendamento) {
        errors.dataAgendamento = "Campo obrigatório";
      }
      if (!values.horaInicial || values.horaInicial?.length === 0 || values.horaInicial.includes("_")) {
        errors.horaInicial = "Campo obrigatório";
      } else if (!/^([01]?[0-9]|2[0-4]):[0-5][0-9]$/.test(values.horaInicial)) {
        errors.horaInicial = "Formato inválido";
      }

      if (!values.horaFinal || values.horaInicial?.length === 0 || values.horaFinal.includes("_")) {
        errors.horaFinal = "Campo obrigatório";
      } else if (!/^([01]?[0-9]|2[0-4]):[0-5][0-9]$/.test(values.horaFinal)) {
        errors.horaFinal = "Formato inválido";
      }

      if (!values.valor || values.valor === 0) {
        errors.valor = "Campo obrigatório";
      }
      return errors;
    },
    onSubmit: (values) => {
      onSaveMetodo(values);
      formik.resetForm();
    },
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  const abrirDialogSalas = () => {
    if (!permissaoPesquisaSala) {
      alert("Você não tem permissão para pesquisar salas!");
      return;
    }
    setDialogSalaVisible(true);
  };

  const isFormSalaInvalid = () => {
    return !!(formik.touched.sala && formik.errors.sala);
  };

  const getFormSalaMessage = () => {
    return isFormSalaInvalid() ? <small className="p-error">{formik.errors.sala}</small> : <></>;
  };

  const onChangeHoraInicial = (e) => {
    formik.setFieldValue("horaInicial", e.target.value);

    const horaInicial = e.target.value.split(":");
    const horaFinal =
      !formik.values.horaFinal || formik.values.horaFinal.includes("_")
        ? e.target.value.split(":")
        : formik.values.horaFinal.split(":");

    horaFinal[0] = parseInt(horaInicial[0]) + diffTime.diffHour;
    horaFinal[1] = parseInt(horaInicial[1]) + diffTime.diffMin;

    formik.setFieldValue("horaFinal", `${horaFinal[0].toString().padStart(2, "0")}:${horaFinal[1].toString().padStart(2, "0")}`);
  };

  const onChangeHoraFinal = (e) => {
    formik.setFieldValue("horaFinal", e.target.value);
    formik.setFieldTouched("horaFinal", true);
  };

  return (
    <>
      <Dialog
        header="Alterar Agendamento"
        visible={dialogVisible}
        onHide={() => {
          formik.resetForm();
          setDialogVisible(false);
        }}
        onShow={() => {
          const dataSplit = agendamento.dataAgendamento.split("T")[0].split("-");
          const dataAgendamento = new Date(dataSplit[0], dataSplit[1] - 1, dataSplit[2]);
          formik.setValues({
            id: agendamento.id,
            paciente: { id: agendamento.pacienteId, nome: agendamento.pacienteNome },
            profissional: { id: agendamento.profissionalId, nome: agendamento.profissionalNome },
            dataAgendamento: dataAgendamento,
            horaInicial: agendamento.horaInicial,
            horaFinal: agendamento.horaFinal,
            sala: { id: agendamento.salaId, nome: agendamento.salaNome },
            salaId: agendamento.salaId,
            valor: agendamento.valor,
            presencial: agendamento.salaId ? true : false,
          });
        }}
        style={{ width: "85vw" }}
      >
        <div className="formgrid grid">
          <div className="field col-12 lg:col-6 md:col-6">
            <label htmlFor="paciente">Paciente</label>
            <div className="flex">
              <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                <span>{agendamento.pacienteNome}</span>
              </span>
            </div>
          </div>
          <div className="field col-12 lg:col-6 md:col-6">
            <label htmlFor="profissional">Profissional</label>
            <div className="flex">
              <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                <span>{agendamento.profissionalNome}</span>
              </span>
            </div>
          </div>
          <div className="field col-12 lg:col-4">
            <label htmlFor="dataAgendamento">Data</label>
            <Calendar
              id="dataAgendamento"
              value={formik.values.dataAgendamento}
              onChange={(e) => formik.setFieldValue("dataAgendamento", e.value)}
              dateFormat="dd/mm/yy"
              className={classNames(
                {
                  "p-invalid": isFormFieldInvalid("dataAgendamento"),
                },
                "w-full"
              )}
              showIcon
              touchUI={window.innerWidth < 768}
              showOnFocus={false}
              mask="99/99/9999"
            />
            {getFormErrorMessage("dataAgendamento")}
          </div>
          <div className="field col-6 lg:col-4">
            <label htmlFor="horaInicial">Horário Inicial</label>
            <InputMask
              value={formik.values.horaInicial}
              onChange={(e) => onChangeHoraInicial(e)}
              mask="99:99"
              placeholder="99:99"
              className={classNames({ "p-invalid": isFormFieldInvalid("horaInicial") }, "w-full")}
            />
            {getFormErrorMessage("horaInicial")}
          </div>
          <div className="field col-6 lg:col-4">
            <label htmlFor="horaFinal">Horário Final</label>
            <InputMask
              value={formik.values.horaFinal}
              onChange={(e) => onChangeHoraFinal(e)}
              onBlur={() => {
                const horaInicial = formik.values.horaInicial.split(":");
                const horaFinal =
                  !formik.values.horaFinal || formik.values.horaFinal.includes("_")
                    ? formik.values.horaInicial.split(":")
                    : formik.values.horaFinal.split(":");
                const diffHour = parseInt(horaFinal[0]) - parseInt(horaInicial[0]);
                const diffMin = parseInt(horaFinal[1]) - parseInt(horaInicial[1]);
                setDiffTime({ diffHour: diffHour < 0 ? 1 : diffHour, diffMin: diffMin < 0 ? 0 : diffMin });
              }}
              mask="99:99"
              placeholder="99:99"
              className={classNames({ "p-invalid": isFormFieldInvalid("horaFinal") }, "w-full")}
            />
            {getFormErrorMessage("horaFinal")}
          </div>
          <div className="field col-12">
            <label htmlFor="valor">Valor</label>
            <InputNumber
              id="valor"
              name="valor"
              value={formik.values.valor}
              onValueChange={(e) => formik.setFieldValue("valor", e.value)}
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              className={classNames({ "p-invalid": isFormFieldInvalid("valor") }, "w-full")}
            />
            {getFormErrorMessage("valor")}
          </div>
          <Divider />
          <div className="field col-12 md:col-5">
            <label htmlFor="presencial">Como os eventos acontecerão?</label>
            <Dropdown
              id="presencial"
              name="presencial"
              inputId="presencialFor"
              value={formik.values.presencial}
              onChange={(e) => {
                formik.setFieldValue("presencial", e.value);
                formik.setFieldValue("sala", null);
                formik.setFieldValue("salaId", null);
              }}
              options={[
                { label: "Presencial", value: true },
                { label: "Online", value: false },
              ]}
              optionValue="value"
              placeholder="Selecione"
              className={classNames({ "p-invalid": isFormFieldInvalid("presencial") }, "w-full")}
            />
            {getFormErrorMessage("presencial")}
          </div>
          <div className={classNames("field col-12 md:col-7", { hidden: formik.values.presencial })} />
          <div className={classNames("field col-12 md:col-7", { hidden: !formik.values.presencial })}>
            <label htmlFor="sala">Sala</label>
            <div className="flex" onClick={() => abrirDialogSalas()}>
              <InputText
                value={formik.values.sala?.nome}
                className={classNames(
                  {
                    "p-invalid": isFormFieldInvalid("sala"),
                  },
                  "w-full flex-grow-1 mr-2"
                )}
                style={{ cursor: "pointer" }}
                readOnly
              />
              <Button icon="pi pi-search" className={classNames("flex-none")} />
            </div>
            {getFormSalaMessage("sala")}
          </div>
        </div>
        <div className="buttons">
          <Button label="Confimar" severity="success" type="submit" onClick={() => formik.handleSubmit()} icon="pi pi-check" />
          <Button label="Cancelar" onClick={() => setDialogVisible(false)} className="p-button-secondary" icon="pi pi-times" />
        </div>
      </Dialog>

      {permissaoPesquisaSala && (
        <DialogSala
          dialogSalaVisible={dialogSalaVisible}
          setDialogSalaVisibleVisible={setDialogSalaVisible}
          onSelectMetodo={(sala) => {
            formik.setFieldValue("sala", sala);
            formik.setFieldValue("salaId", sala.id);
          }}
        />
      )}
    </>
  );
};

export default DialogAgendamentoAlterar;
