import responsavelService from "../../../services/responsavel.service";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useRef, useState } from "react";

import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { Divider } from "primereact/divider";
import { useMountEffect } from "primereact/hooks";
import { classNames } from "primereact/utils";
import * as Yup from "yup";
import apoioService from "../../../services/apoio.service";

const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Campo obrigatório."),
  email: Yup.string()
    .matches("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$", "Email inválido. (Ex: email@email.com)")
    .nullable(true),
});

const DialogResponsavel = ({ dialogVisible, setDialogVisible, pacienteId, responsavelId, onSave, mostrarMensagem }) => {
  const [tipoSexo, setTipoSexo] = useState([]);
  const [tipoEstadoCivil, setTipoEstadoCivil] = useState([]);
  const [tipoEscolaridade, setTipoEscolaridade] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const toast = useRef(null);

  const formik = useFormik({
    initialValues: {
      pacienteId: "",
      id: undefined,
      nome: "",
      cpf: undefined,
      rg: undefined,
      dataNascimento: "",
      sexo: undefined,
      sexoId: undefined,
      estadoCivil: undefined,
      estadoCivilId: undefined,
      profissao: undefined,
      escolaridade: undefined,
      escolaridadeId: undefined,
      telefone: undefined,
      email: undefined,
      enderecoRua: undefined,
      enderecoNumero: undefined,
      enderecoComplemento: undefined,
      enderecoBairro: undefined,
      enderecoCidade: undefined,
      enderecoEstado: undefined,
      enderecoCep: undefined,
    },
    validationSchema,
    onSubmit: (values) => {
      if (values.sexo) values.sexoId = values.sexo.id;
      if (values.estadoCivil) values.estadoCivilId = values.estadoCivil.id;
      if (values.escolaridade) values.escolaridadeId = values.escolaridade.id;
      setLoadingSubmit(true);
      if (values.id) {
        responsavelService
          .alterarResponsavel(values)
          .then((response) => {
            mostrarMensagem("Responsável alterado com sucesso.", "success", null, true);
            formik.resetForm();
            setDialogVisible(false);
            setLoadingSubmit(false);
            onSave();
          })
          .catch((error) => {
            mostrarMensagem(error, "error");
            setLoadingSubmit(false);
          });
      } else {
        responsavelService
          .cadastrarResponsavel(values)
          .then(() => {
            mostrarMensagem("Responsável cadastrado com sucesso.", "success", null, true);
            formik.resetForm();
            setDialogVisible(false);
            setLoadingSubmit(false);
            onSave();
          })
          .catch((error) => {
            mostrarMensagem(error, "error");
            setLoadingSubmit(false);
          });
      }
    },
  });

  useMountEffect(() => {
    apoioService.listaTipoSexo().then((result) => setTipoSexo(result));
    apoioService.listaTipoEstadoCivil().then((result) => setTipoEstadoCivil(result));
    apoioService.listaTipoEscolaridade().then((result) => setTipoEscolaridade(result));
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  return (
    <>
      <Dialog
        header="Responsável"
        visible={dialogVisible}
        style={{ width: "90vw" }}
        onHide={() => {
          formik.resetForm();
          setDialogVisible(false);
        }}
        onShow={() => {
          formik.resetForm();
          if (responsavelId)
            responsavelService
              .pesquisarResponsavel(responsavelId)
              .then((result) => formik.setValues({ ...result.data, pacienteId: pacienteId }));
          else formik.setValues({ pacienteId: pacienteId });
        }}
      >
        <div className="formgrid grid">
          <div className="field col-12 md:col-8">
            <label htmlFor="nome">Nome</label>
            <InputText
              id="nome"
              value={formik.values.nome}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("nome", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("nome") }, "w-full")}
            />
            {getFormErrorMessage("nome")}
          </div>
          <div className="field col-12 lg:col-4 md:col-4">
            <label htmlFor="dataNascimento">Data de Nascimento</label>
            <Calendar
              id="dataNascimento"
              value={formik.values.dataNascimento !== null ? new Date(formik.values.dataNascimento) : null}
              onChange={(e) => {
                if (e.target.value === "") e.target.value = null;
                if (e.target.value !== null) {
                  formik.setFieldValue("dataNascimento", new Date(e.value).toISOString());
                } else {
                  formik.setFieldValue("dataNascimento", null);
                }
              }}
              showIcon
              className={classNames({ "p-invalid": isFormFieldInvalid("dataNascimento") }, "w-full")}
              touchUI={window.innerWidth < 768}
              showOnFocus={false}
              mask="99/99/9999"
            />
            {getFormErrorMessage("dataNascimento")}
          </div>
          <div className="field col-12 lg:col-3 md:col-4">
            <label htmlFor="sexo">Sexo</label>
            <Dropdown
              id="sexo"
              value={formik.values.sexo}
              onChange={(e) => formik.setFieldValue("sexo", e.target.value)}
              options={tipoSexo}
              optionLabel="descricao"
              placeholder="Selecione o sexo"
              className={classNames({ "p-invalid": isFormFieldInvalid("sexo") }, "w-full")}
            />
          </div>
          <div className="field col-12 lg:col-3 md:col-4">
            <label htmlFor="estadoCivil">Estado Civil</label>
            <Dropdown
              id="estadoCivil"
              value={formik.values.estadoCivil}
              onChange={(e) => formik.setFieldValue("estadoCivil", e.target.value)}
              options={tipoEstadoCivil}
              optionLabel="descricao"
              placeholder="Selecione o estado civil"
              className={classNames({ "p-invalid": isFormFieldInvalid("estadoCivil") }, "w-full")}
            />
          </div>
          <div className="field col-12 lg:col-3 md:col-4">
            <label htmlFor="profissao">Profissão</label>
            <InputText
              id="profissao"
              value={formik.values.profissao}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("profissao", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("profissao") }, "w-full")}
            />
          </div>
          <div className="field col-12 lg:col-3 md:col-4">
            <label htmlFor="escolaridade">Escolaridade</label>
            <Dropdown
              id="escolaridade"
              value={formik.values.escolaridade}
              onChange={(e) => formik.setFieldValue("escolaridade", e.target.value)}
              options={tipoEscolaridade}
              optionLabel="descricao"
              placeholder="Selecione a escolaridade"
              className={classNames({ "p-invalid": isFormFieldInvalid("escolaridade") }, "w-full")}
            />
          </div>

          <div className="field col-12 lg:col-3 md:col-4">
            <label htmlFor="cpf">CPF</label>
            <InputMask
              id="cpf"
              value={formik.values.cpf}
              onChange={(e) => {
                if (e.target.value === "") e.target.value = null;
                formik.setFieldValue("cpf", e.target.value);
              }}
              mask="999.999.999-99"
              placeholder="999.999.999-99"
              className={classNames({ "p-invalid": isFormFieldInvalid("cpf") }, "w-full")}
            />
            {getFormErrorMessage("cpf")}
          </div>
          <div className="field col-12 lg:col-3 md:col-4">
            <label htmlFor="rg">RG</label>
            <InputMask
              id="rg"
              value={formik.values.rg}
              onChange={(e) => {
                if (e.target.value === "") e.target.value = null;
                formik.setFieldValue("rg", e.target.value);
              }}
              mask="99.999.999-*"
              placeholder="99.999.999-9"
              className="w-full"
            />
          </div>

          <div className="field col-12 lg:col-3 md:col-4">
            <label htmlFor="telefone">Telefone</label>
            <InputMask
              id="telefone"
              value={formik.values.telefone}
              onChange={(e) => {
                if (e.target.value === "") e.target.value = null;
                formik.setFieldValue("telefone", e.target.value);
              }}
              mask="(99) 99999-9999"
              placeholder="(99) 99999-9999"
              className={classNames({ "p-invalid": isFormFieldInvalid("telefone") }, "w-full")}
            />
            {getFormErrorMessage("telefone")}
          </div>
          <div className="field col-12 lg:col-3 md:col-8">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              value={formik.values.email}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("email") }, "w-full")}
            />
            {getFormErrorMessage("email")}
          </div>
        </div>
        <Divider />
        <div className="mb-2">
          <span style={{ fontSize: "1.2rem", fontWeight: "700", marginBottom: "0.5rem" }}>Endereço</span>
        </div>
        <div className="formgrid grid">
          <div className="field col-12 lg:col-3 md:col-4">
            <label htmlFor="cep">CEP</label>
            <InputMask
              id="cep"
              className="w-full"
              value={formik.values.enderecoCep}
              onChange={(e) => {
                if (e.target.value === "") e.target.value = null;
                formik.setFieldValue("enderecoCep", e.target.value);
              }}
              mask="99999-999"
              placeholder="99999-999"
            />
          </div>
          <div className="field col-12 lg:col-9 md:col-8">
            <label htmlFor="rua">Rua</label>
            <InputText
              id="rua"
              className="w-full"
              value={formik.values.enderecoRua}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("enderecoRua", e.target.value)}
            />
          </div>
          <div className="field col-12 lg:col-3 md:col-6">
            <label htmlFor="numero">Número</label>
            <InputText
              id="numero"
              className="w-full"
              value={formik.values.enderecoNumero}
              maxLength={50}
              onChange={(e) => formik.setFieldValue("enderecoNumero", e.target.value)}
            />
          </div>
          <div className="field col-12 lg:col-3 md:col-6">
            <label htmlFor="bairro">Bairro</label>
            <InputText
              id="bairro"
              className="w-full"
              value={formik.values.enderecoBairro}
              maxLength={100}
              onChange={(e) => formik.setFieldValue("enderecoBairro", e.target.value)}
            />
          </div>
          <div className="field col-12 lg:col-3 md:col-6">
            <label htmlFor="cidade">Cidade</label>
            <InputText
              id="cidade"
              className="w-full"
              value={formik.values.enderecoCidade}
              maxLength={100}
              onChange={(e) => formik.setFieldValue("enderecoCidade", e.target.value)}
            />
          </div>
          <div className="field col-12 lg:col-3 md:col-6">
            <label htmlFor="estado">Estado</label>
            <InputText
              id="estado"
              className="w-full"
              value={formik.values.enderecoEstado}
              maxLength={100}
              onChange={(e) => formik.setFieldValue("enderecoEstado", e.target.value)}
            />
          </div>
          <div className="field col-12">
            <label htmlFor="complemento">Complemento</label>
            <InputTextarea
              id="complemento"
              className="w-full"
              value={formik.values.enderecoComplemento}
              maxLength={255}
              onChange={(e) => formik.setFieldValue("enderecoComplemento", e.target.value)}
              rows={1}
              cols={30}
              autoResize
            />
          </div>
        </div>
        <Divider />
        <div className="buttons">
          <Button
            label="Salvar"
            icon="pi pi-save"
            severity="success"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
            loading={loadingSubmit}
          />
          <Button label="Cancelar" icon="pi pi-times" severity="secondary" onClick={() => setDialogVisible(false)} />
        </div>
      </Dialog>
    </>
  );
};

export default DialogResponsavel;
