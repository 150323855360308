import React, { useState } from "react";

import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import authService from "../../../services/auth.service";
import pacienteService from "../../../services/paciente.service";

const DialogPaciente = ({ dialogPacienteVisible, setDialogPacienteVisible, onSelectMetodo }) => {
  const permissaoPesquisaPaciente = authService.checkIfHasPermissao(["PES_PACIENTE"]);

  const [pacientes, setPacientes] = useState([]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const pesquisarPacientes = () => {
    setPacientes([]);
    pacienteService.resgatarPacientes().then((result) => setPacientes(result));
  };

  return (
    <Dialog
      onShow={() => {
        if (!permissaoPesquisaPaciente) {
          alert("Você não tem permissão para pesquisar pacientes!");
          setDialogPacienteVisible(false);
          return;
        }
        pesquisarPacientes();
        onGlobalFilterChange({ target: { value: "" } });
      }}
      header="Pacientes"
      visible={dialogPacienteVisible}
      onHide={() => setDialogPacienteVisible(false)}
      style={{ width: "90vw" }}
    >
      <div className="grid">
        <div className="field col" style={{ minWidth: "200px" }}>
          <div className="flex w-full">
            <span className="p-input-icon-left w-full mr-2">
              <i className="pi pi-search" />
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Procurar..." className="w-full" />
            </span>

            <Button icon="pi pi-refresh" onClick={() => pesquisarPacientes()} className="flex-none" severity="info" />
          </div>
        </div>
      </div>
      <DataTable
        value={pacientes}
        emptyMessage="Nenhum registro encontrado!"
        paginator
        rows={5}
        dataKey="id"
        filters={filters}
        globalFilterFields={["nome"]}
        selectionMode="single"
        onSelectionChange={(e) => {
          setDialogPacienteVisible(false);
          onSelectMetodo(e.value);
        }}
      >
        <Column field="nome" header="Nome"></Column>
        <Column field="idade" header="Idade"></Column>
        <Column
          style={{ width: "1rem" }}
          body={(rowData) => (
            <>
              <Button
                icon="pi pi-check"
                size="small"
                severity="success"
                onClick={() => {
                  setDialogPacienteVisible(false);
                  onSelectMetodo(rowData);
                }}
              />
            </>
          )}
        />
      </DataTable>
    </Dialog>
  );
};

export default DialogPaciente;
