import axiosInstance from "./baseService.service";

const API_URL = "mensagem/";

const avisoService = {
  pesquisarAvisosMensagem: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "pesquisarAvisosMensagem");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  gerarAvisoConfirmacaoAgendamento: async (agendamentoId) => {
    try {
      const response = await axiosInstance.get(API_URL + "gerarAvisoConfirmacaoAgendamento?agendamentoId=" + agendamentoId);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  cadastrarAvisoMensagem: async (aviso) => {
    try {
      const response = await axiosInstance.post(API_URL + "cadastrarAvisoMensagem", aviso);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  alterarAvisoMensagem: async (aviso) => {
    try {
      const response = await axiosInstance.post(API_URL + "alterarAvisoMensagem", aviso);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  excluirAvisoMensagem: async (id) => {
    try {
      const response = await axiosInstance.post(API_URL + "excluirAvisoMensagem?id=" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default avisoService;
