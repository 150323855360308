import empresaUsuarioService from "../../../services/empresaUsuario.service";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useCallback, useEffect, useState } from "react";

import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import * as Yup from "yup";
import apoioService from "../../../services/apoio.service";
import DialogProfissional from "../../loggedIn/common/dialogProfissional.component";
import DialogSenhaUsuarioEmpresa from "./dialogSenha.component";
import authService from "../../../services/auth.service";

const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Campo obrigatório."),
  senha: Yup.string().required("Campo obrigatório."),
  email: Yup.string()
    .matches("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$", "Email inválido. (Ex: email@email.com)")
    .required("Campo obrigatório."),
});

const EmpresaUsuarioPage = ({ mostrarMensagem }) => {
  const [permissoes, setPermissoes] = useState([{}]);
  const [loading, setLoading] = useState(true);
  const [empresaUsuarios, setEmpresaUsuarios] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogVisibleResetSenha, setDialogVisibleResetSenha] = useState(false);
  const [dialogProfissionalVisible, setDialogProfissionalVisible] = useState(false);
  const [novo, setNovo] = useState(true);

  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 5,
    page: 0,
    sortField: null,
    sortOrder: null,
    text: "",
  });

  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const formik = useFormik({
    initialValues: {
      id: null,
      nome: "",
      email: "",
      senha: "",
      profissional: {},
      profissionalId: "",
      permissoes: [],
    },
    validationSchema,
    onSubmit: (values) => {
      if (novo) {
        empresaUsuarioService
          .cadastrarUsuario(values)
          .then((result) => {
            mostrarMensagem("Usuário cadastrado com sucesso!", "success");
            setDialogVisible(false);
            pesquisarUsuarios();
          })
          .catch((error) => {
            mostrarMensagem(error, "error", null, true);
          });
      } else {
        empresaUsuarioService
          .alterarUsuario(values)
          .then((result) => {
            mostrarMensagem("Usuário alterado com sucesso!", "success");
            setDialogVisible(false);
            pesquisarUsuarios();
          })
          .catch((error) => {
            mostrarMensagem(error, "error", null, true);
          });
      }
    },
  });

  const pesquisarUsuarios = useCallback(() => {
    setLoading(true);
    empresaUsuarioService.pesquisarTodos(lazyState).then((result) => {
      setEmpresaUsuarios(result.usuarios);
      setTotalRecords(result.totalRecords);
      setLoading(false);
    });
  }, [lazyState]);

  useEffect(() => {
    pesquisarUsuarios();

    apoioService.listaPermissao().then((result) => setPermissoes(result));
  }, [pesquisarUsuarios]);

  const onPage = (event) => {
    event.text = globalFilterValue;
    setlazyState(event);
  };

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  return (
    <>
      <Card title="Usuários da Empresa">
        <div className="grid">
          <div className="field col-fixed">
            <Button
              label="Novo"
              icon="pi pi-plus"
              severity="success"
              className="flex-none"
              onClick={() => {
                setNovo(true);
                formik.resetForm();
                setDialogVisible(true);
              }}
            />
          </div>
          <div className="field col" style={{ minWidth: "200px" }}>
            <div className="flex w-full">
              <span className="p-input-icon-left w-full mr-2">
                <i className="pi pi-search" />
                <InputText
                  value={globalFilterValue}
                  onChange={(e) => {
                    const value = e.target.value;
                    setGlobalFilterValue(value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setlazyState({ ...lazyState, text: globalFilterValue });
                    }
                  }}
                  placeholder="Procurar..."
                  className="w-full"
                />
              </span>

              <Button
                icon="pi pi-search"
                onClick={() => {
                  setlazyState({ ...lazyState, text: globalFilterValue });
                }}
                className="flex-none mr-1"
                severity="info"
              />
              <Button
                icon="pi pi-times"
                disabled={lazyState.text === "" && globalFilterValue === ""}
                onClick={() => {
                  setGlobalFilterValue("");
                  setlazyState({ ...lazyState, text: "" });
                }}
                className="flex-none"
                severity="danger"
              />
            </div>
          </div>
        </div>
        <DataTable
          lazy
          first={lazyState.first}
          rows={5}
          totalRecords={totalRecords}
          onPage={onPage}
          paginator
          value={empresaUsuarios}
          rowsPerPageOptions={[5, 10, 25, 50]}
          emptyMessage="Nenhum registro encontrado!"
          dataKey="id"
          loading={loading}
        >
          <Column field="nome" header="Nome" />
          <Column field="email" header="Email" />
          <Column
            style={{ width: "1rem" }}
            field="edit"
            body={(rowData) => (
              <Button
                icon="pi pi-pencil"
                size="small"
                onClick={() => {
                  formik.setValues(rowData);
                  formik.setFieldValue("senha", "XXXXXX");
                  setNovo(false);
                  setDialogVisible(true);
                }}
              />
            )}
          />
        </DataTable>
      </Card>

      <Dialog
        header={
          <div className="grid mr-1">
            <div className="col">
              <span className="p-card-title">Usuário</span>
            </div>
            <div className="col-fixed">
              <Button
                label="Alterar Senha"
                icon="pi pi-refresh"
                severity="success"
                type="submit"
                className={classNames({ hidden: novo })}
                onClick={() => {
                  setDialogVisibleResetSenha(true);
                }}
                size="small"
              />
            </div>
          </div>
        }
        visible={dialogVisible}
        style={{ width: "90vw" }}
        onHide={() => {
          setDialogVisible(false);
          formik.resetForm();
        }}
      >
        <div className="formgrid grid">
          <div className="field col-12">
            <label htmlFor="nome">Nome</label>
            <InputText
              id="nome"
              value={formik.values.nome}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("nome", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("nome") }, "w-full")}
            />
            {getFormErrorMessage("nome")}
          </div>
          <div className="field col-12">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              value={formik.values.email}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("email") }, "w-full")}
              disabled={!novo}
            />
            {getFormErrorMessage("email")}
          </div>
          <div className={classNames("field col-12", { hidden: !novo })}>
            <label htmlFor="senha">Senha</label>
            <InputText
              id="senha"
              type="password"
              value={formik.values.senha}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("senha", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("senha") }, "w-full")}
              disabled={!novo}
            />
            {getFormErrorMessage("senha")}
          </div>
          <div className="field col-12">
            <label htmlFor="profissional">Profissional</label>
            <div
              className="flex"
              onClick={() => {
                if (authService.checkIfHasPermissao(["PES_PROFISSIONAL"])) setDialogProfissionalVisible(true);
              }}
            >
              <InputText
                value={formik.values.profissional?.nome}
                className={classNames(
                  {
                    "p-invalid": isFormFieldInvalid("profissional"),
                  },
                  "hidden-disabled w-full flex-grow-1 mr-2"
                )}
                readOnly
              />
              <Button
                icon="pi pi-search"
                onClick={() => {
                  setDialogProfissionalVisible(true);
                }}
                className={classNames("flex-none")}
                visible={authService.checkIfHasPermissao(["PES_PROFISSIONAL"])}
              />
            </div>
            {getFormErrorMessage("profissional")}
          </div>
          <div className="field col-12">
            <DataTable
              value={permissoes}
              selection={formik.values.permissoes}
              dataKey="id"
              selectionMode={"checkbox"}
              onSelectionChange={(e) => formik.setFieldValue("permissoes", e.value)}
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
              <Column field="roleCode" header="Permissão" />
              <Column field="roleDescription" header="Descrição" />
            </DataTable>
          </div>
        </div>

        <div className="buttons mt-2">
          <Button
            label="Salvar"
            icon="pi pi-save"
            severity="success"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
          />
          <Button label="Voltar" icon="pi pi-times" severity="secondary" type="submit" onClick={() => setDialogVisible(false)} />
        </div>
      </Dialog>

      <DialogSenhaUsuarioEmpresa
        dialogSenhaVisible={dialogVisibleResetSenha}
        setDialogSenhaVisible={setDialogVisibleResetSenha}
        onSave={(values) => {
          empresaUsuarioService.alterarSenha(values, formik.values.id).then((result) => {
            if (result) {
              mostrarMensagem("Senha alterada com sucesso!", "success");
              setDialogVisible(false);
              setDialogVisibleResetSenha(false);
            } else {
              mostrarMensagem("error", "Erro ao alterar senha!");
            }
          });
        }}
      />

      <DialogProfissional
        dialogProfissionalVisible={dialogProfissionalVisible}
        setDialogProfissionalVisible={setDialogProfissionalVisible}
        onSelectMetodo={(profissional) => {
          formik.setFieldValue("profissional", profissional);
          formik.setFieldValue("profissionalId", profissional.id);
        }}
      />
    </>
  );
};

export default EmpresaUsuarioPage;
