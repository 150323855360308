import responsavelService from "../../../services/responsavel.service";

import { Button } from "primereact/button";
import React, { useCallback, useEffect, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import authService from "../../../services/auth.service";
import DialogResponsavel from "./dialogResponsavel.component";

const TabResponsavel = ({ pacienteId, mostrarMensagem }) => {
  const permissaoCadastroResponsavel = authService.checkIfHasPermissao(["CAD_RESPONSAVEL"]);

  const [dialogVisible, setDialogVisible] = useState(false);
  const [responsavelId, setResponsavelId] = useState(null);

  const [loading, setLoading] = useState(true);
  const [salas, setSalas] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 5,
    page: 0,
    sortField: "nome",
    sortOrder: true,
    filters: {
      nome: { value: "", matchMode: "contains" },
    },
  });

  const pesquisarResponsaveis = useCallback(() => {
    setLoading(true);
    responsavelService.pesquisarResponsaveis(lazyState, pacienteId).then((result) => {
      setTotalRecords(result.total);
      setSalas(result.salas);
      setLoading(false);
    });
  }, [lazyState, pacienteId]);

  useEffect(() => {
    pesquisarResponsaveis();
  }, [pesquisarResponsaveis]);

  const onPage = (event) => {
    setlazyState(event);
  };

  return (
    <>
      <div className="grid mb-2">
        <div className="field col">
          <span style={{ fontSize: "1.2rem", fontWeight: "700", marginBottom: "0.5rem" }}>Responsáveis</span>
        </div>
        <div className="field col-fixed">
          <Button
            label="Adicionar"
            icon="pi pi-plus"
            severity="success"
            size="small"
            onClick={() => {
              setResponsavelId(null);
              setDialogVisible(true);
            }}
            disabled={!permissaoCadastroResponsavel}
          />
        </div>
      </div>
      <DataTable
        paginator
        rows={lazyState.rows}
        rowsPerPageOptions={[5, 10, 25, 50]}
        emptyMessage="Nenhum registro encontrado!"
        dataKey="id"
        loading={loading}
        first={lazyState.first}
        totalRecords={totalRecords}
        value={salas}
        onPage={onPage}
        lazy
        sortField={lazyState.sortField}
        sortOrder={lazyState.sortOrder}
        filters={lazyState.filters}
      >
        <Column field="nome" header="Nome" />
        <Column field="telefone" header="Telefone" />
        <Column
          style={{ width: "1rem" }}
          field="edit"
          body={(rowData) => (
            <Button
              icon="pi pi-pencil"
              size="small"
              onClick={() => {
                setResponsavelId(rowData.id);
                setDialogVisible(true);
              }}
              disabled={!permissaoCadastroResponsavel}
            />
          )}
        />
      </DataTable>
      <DialogResponsavel
        dialogVisible={dialogVisible}
        setDialogVisible={setDialogVisible}
        pacienteId={pacienteId}
        responsavelId={responsavelId}
        onSave={pesquisarResponsaveis}
        mostrarMensagem={mostrarMensagem}
      />
    </>
  );
};

export default TabResponsavel;
