import React, { useState } from "react";

import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import profissionalService from "../../../services/profissional.service";

const DialogProfissional = ({ dialogProfissionalVisible, setDialogProfissionalVisible, onSelectMetodo }) => {
  const [profissionais, setProfissionais] = useState([]);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const pesquisarProfissionais = () => {
    setProfissionais([]);
    profissionalService.pesquisarProfissionais().then((result) => setProfissionais(result.data));
  };

  return (
    <Dialog
      onShow={() => {
        pesquisarProfissionais();
        onGlobalFilterChange({ target: { value: "" } });
        setDialogProfissionalVisible(true);
      }}
      header="Profissionais"
      visible={dialogProfissionalVisible}
      onHide={() => setDialogProfissionalVisible(false)}
      style={{ width: "90vw" }}
    >
      <div className="grid">
        <div className="field col" style={{ minWidth: "200px" }}>
          <div className="flex w-full">
            <span className="p-input-icon-left w-full mr-2">
              <i className="pi pi-search" />
              <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Procurar..." className="w-full" />
            </span>

            <Button icon="pi pi-refresh" onClick={() => pesquisarProfissionais()} className="flex-none" severity="info" />
          </div>
        </div>
      </div>
      <DataTable
        value={profissionais}
        emptyMessage="Nenhum profissional encontrado!"
        paginator
        rows={5}
        dataKey="id"
        filters={filters}
        globalFilterFields={["nome", "cargo.nome"]}
        selectionMode="single"
        onSelectionChange={(e) => {
          setDialogProfissionalVisible(false);
          onSelectMetodo(e.value);
        }}
      >
        <Column field="cargo.nome" header="Cargo" sortable></Column>
        <Column field="nome" header="Nome"></Column>
        <Column
          style={{ width: "1rem" }}
          body={(rowData) => (
            <>
              <Button
                icon="pi pi-check"
                size="small"
                severity="success"
                onClick={() => {
                  setDialogProfissionalVisible(false);
                  onSelectMetodo(rowData);
                }}
              />
            </>
          )}
        />
      </DataTable>
    </Dialog>
  );
};

export default DialogProfissional;
