import AuthService from "../../../services/auth.service";

import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { Card } from "primereact/card";

const LoginPage = ({ mostrarMensagem }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = () => {
    AuthService.login(username, password)
      .then(() => {
        window.location.href = "/home?welcome";
      })
      .catch((error) => {
        if (error.response.status === 401) mostrarMensagem("Email ou Senha incorreto", "error");
        else mostrarMensagem(error, "error");
      });
  };

  return (
    <>
      <Card title="Entrar">
        <div className="field">
          <label htmlFor="username">Email</label>
          <InputText id="username" className="w-full" value={username} onChange={(e) => setUsername(e.target.value)} />
        </div>
        <div className="field">
          <label htmlFor="password">Senha</label>
          <Password
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            feedback={false}
            className="w-full"
          />
        </div>
        <div className="flex flex-wrap align-items-center justify-content-center mt-4">
          <Button
            label="Entrar"
            icon="pi pi-save"
            severity="success"
            type="submit"
            onClick={() => {
              handleLogin();
            }}
          />
        </div>
        <div className="buttons">
          <Button
            label="Esqueceu sua senha?"
            onClick={() => {
              window.location.href = "/recuperarConta";
            }}
            link
          ></Button>
        </div>
        <div className="buttons">
          <p>
            Ainda não é tem uma conta?{" "}
            <a className="p-button-label p-c" href="/registrar">
              Registre-se
            </a>{" "}
            e comece agora!
          </p>
        </div>
      </Card>
    </>
  );
};

export default LoginPage;
