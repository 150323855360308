import contaService from "../../../../services/conta.service";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import * as Yup from "yup";
import DialogContaLoading from "./dialogContaLoading.component";
import apoioService from "../../../../services/apoio.service";

const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Nome é obrigatório."),
  saldo: Yup.number().required("Saldo é obrigatório.").max(10000000000, "Saldo não pode ser maior que 10000000000"),
  tipoConta: Yup.object().required("Tipo de conta é obrigatório."),
});

const DialogConta = ({ dialogContaVisible, setDialogContaVisible, onSaveMetodo, contaId, mostrarMensagem }) => {
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [tipoConta, setTipoConta] = useState([]);

  useEffect(() => {
    apoioService.listaTipoContaFinanceiro().then((result) => setTipoConta(result));
  }, []);

  const formik = useFormik({
    initialValues: {
      id: null,
      nome: "",
      saldo: 0,
      tipoConta: null,
      tipoContaId: null,
      descricao: null,
    },
    validationSchema,
    onSubmit: (values) => {
      setLoadingSubmit(true);
      if (contaId === null) {
        contaService
          .cadastrarConta(values)
          .then((response) => {
            onSaveMetodo();
            setDialogContaVisible(false);
            setLoadingSubmit(false);
          })
          .catch((error) => {
            mostrarMensagem(error, "error", null, true);
            setLoadingSubmit(false);
          });
      } else {
        contaService.alterarConta(values).then((response) => {
          onSaveMetodo();
          setDialogContaVisible(false);
          setLoadingSubmit(false);
        });
      }
    },
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name, formik) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  return (
    <>
      <Dialog
        header="Conta"
        visible={dialogContaVisible}
        onHide={() => {
          setDialogContaVisible(false);
          setLoading(true);
        }}
        onShow={() => {
          setLoading(true);
          formik.resetForm();
          if (contaId === null) {
            formik.setValues({
              id: null,
              nome: "",
              saldo: 0,
              tipoConta: null,
              descricao: "",
            });
            setLoading(false);
          } else {
            contaService.pesquisarConta(contaId).then((result) => {
              formik.setValues({
                id: result.id,
                nome: result.nome,
                saldo: result.saldo,
                tipoConta: { id: result.tipoContaId, descricao: result.tipoContaDescricao },
                tipoContaId: result.tipoContaId,
                descricao: result.descricao,
              });
              setLoading(false);
            });
          }
        }}
        style={{ width: "90vw" }}
      >
        {loading ? (
          <DialogContaLoading />
        ) : (
          <>
            <div className="formgrid grid">
              <div className="field col-12">
                <label htmlFor="nome">Nome</label>
                <InputText
                  id="nome"
                  value={formik.values.nome}
                  maxLength={50}
                  onChange={(e) => formik.setFieldValue("nome", e.target.value)}
                  className={classNames({ "p-invalid": isFormFieldInvalid("nome") }, "w-full")}
                />
                {getFormErrorMessage("nome")}
              </div>
              <div className="field col-12 lg:col-6">
                <label htmlFor="saldo">Saldo</label>
                <InputNumber
                  id="saldo"
                  value={formik.values.saldo}
                  max={9999999999.999}
                  onChange={(e) => formik.setFieldValue("saldo", e.value)}
                  className={classNames({ "p-invalid": isFormFieldInvalid("saldo") }, "w-full")}
                  prefix="R$ "
                  minFractionDigits={0}
                  maxFractionDigits={2}
                  disabled={contaId !== null}
                />
                {getFormErrorMessage("saldo")}
              </div>
              <div className="field col-12 lg:col-6">
                <label htmlFor="tipoConta">Tipo Conta</label>
                <Dropdown
                  id="tipoConta"
                  value={formik.values.tipoConta}
                  onChange={(e) => {
                    formik.setFieldValue("tipoConta", e.target.value);
                    formik.setFieldValue("tipoContaId", e.target.value.id);
                  }}
                  options={tipoConta}
                  optionLabel="descricao"
                  placeholder="Selecione o tipo da conta"
                  className={classNames({ "p-invalid": isFormFieldInvalid("tipoConta") }, "w-full")}
                />
                {getFormErrorMessage("tipoConta")}
              </div>
              <div className="field col-12">
                <label htmlFor="descricao">Descrição</label>
                <InputText
                  id="descricao"
                  value={formik.values.descricao}
                  maxLength={255}
                  onChange={(e) => formik.setFieldValue("descricao", e.target.value)}
                  className={classNames({ "p-invalid": isFormFieldInvalid("descricao") }, "w-full")}
                />
                {getFormErrorMessage("descricao")}
              </div>
            </div>
            <div className="buttons mt-2">
              <Button
                label={"Salvar"}
                icon="pi pi-save"
                severity="success"
                onClick={() => formik.handleSubmit()}
                type="submit"
                loading={loadingSubmit}
              />
              <Button label="Voltar" icon="pi pi-times" severity="secondary" onClick={() => setDialogContaVisible(false)} />
            </div>
          </>
        )}
      </Dialog>
    </>
  );
};

export default DialogConta;
