import cargoService from "../../../services/cargo.service";
import profissionalService from "../../../services/profissional.service";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { useMountEffect } from "primereact/hooks";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React, { useState } from "react";

import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import Yup from "../../../util/CustomYup";

import { TabPanel, TabView } from "primereact/tabview";
import { Divider } from "primereact/divider";
import { formatarData } from "../../../util/FormatarData";



const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Campo obrigatório."),
  email: Yup.string().matches("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$", "Email inválido. (Ex: email@email.com)"),
  cargo: Yup.object().required("Campo obrigatório."),
  cpf: Yup.string().validarCPF("CPF Inválido.").nullable(),
});

const ProfissionalEdicao = ({ mostrarMensagem }) => {
  const [profissional, setProfissional] = useState([]);
  const [isEdicao, setIsEdicao] = useState(false);
  const [cargos, setCargos] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);


  const formik = useFormik({
    initialValues: {
      id: "",
      nome: "",
      email: "",
      cargo: null,
      especializacao: "",
      telefone: null,
      dataNascimento: null,
      cpf: null,
      rg: null,
      endereco: {
        cep: null,
        rua: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        complemento: "",
      },
    },
    validationSchema,
    onSubmit: (values) => {
      setLoadingSubmit(true);
      if (isEdicao) {
        profissionalService
          .alterarProfissional(values)
          .then((result) => {
            mostrarMensagem("Profissional alterado com sucesso!", "success");
            setLoadingSubmit(false);
            pesquisarProfissional(formik.values.id);
          })
          .catch((error) => {
            setLoadingSubmit(false);
            mostrarMensagem(error, "error");
            if (error instanceof Array && error.includes("CPF já cadastrado.")) formik.setFieldError("cpf", "CPF já cadastrado.");
          });
      } else {
        profissionalService
          .cadastrarProfissional(values)
          .then((result) => {
            setLoadingSubmit(false);
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("id", result);
            searchParams.set("criado", true);
            window.location.search = searchParams;
          })
          .catch((error) => {
            setLoadingSubmit(false);
            mostrarMensagem(error, "error");
            if (error instanceof Array && error.includes("CPF já cadastrado.")) formik.setFieldError("cpf", "CPF já cadastrado.");
          });
      }
    },
  });

  useMountEffect(() => {
    cargoService.getAllCargos().then((result) => setCargos(result.data));

    const searchParams = new URLSearchParams(window.location.search);
    const idParam = searchParams.get("id");
    if (!idParam) {
      setActiveIndex(1);
      return;
    }

    setIsEdicao(true);

    if (searchParams.has("criado")) mostrarMensagem("Profissional cadastrado com sucesso!", "success");

    if (idParam !== 0 && idParam !== null) {
      pesquisarProfissional(idParam);
    }
  }, [mostrarMensagem, formik]);

  const pesquisarProfissional = (id) => {
    profissionalService
    .pesquisarProfissional(id)
    .then((result) => {
        const data = result.data;
        formik.setValues(data);
        setProfissional(data);
    })
    .catch((error) => {
      mostrarMensagem("Não foi encontrado nenhum profissional com o ID: " + id, "error");
    });
  };


  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  return (
<>
  <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>

    <TabPanel header="Principal" disabled={!formik.values.id}>

      <div className="formigrid grid">

        <div className="field col-12 md:col-8">
          <label htmlFor="nome">Nome</label>
          <div className="flex">
            <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
              {profissional.nome}
            </span>
          </div>
        </div>

        <div className="field col-12 lg:col-4 md:col-4">
          <label htmlFor="dataNascimento">Data de Nascimento</label>
          <div className="flex">
            <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
              {profissional.dataNascimento ? formatarData(profissional.dataNascimento) : "-"}
            </span>
          </div>
        </div>

        <div className="field col-12 lg:col-4 md:col-6">
          <label htmlFor="nome">Cargo</label>
          <div className="flex">
            <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
            {profissional.cargo ? profissional.cargo.nome : "-"}
            </span>
          </div>
        </div>

        <div className="field col-12 lg:col-4 md:col-6">
          <label htmlFor="nome">Especialização</label>
          <div className="flex">
            <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
              {profissional.especializacao ? profissional.especializacao : "-"}
            </span>
          </div>
        </div>

        <div className="field col-12 lg:col-4 md:col-6">
          <label htmlFor="nome">CPF</label>
          <div className="flex">
            <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
              {profissional.cpf ? profissional.cpf : "-"}
            </span>
          </div>
        </div>

        <div className="field col-12 lg:col-4 md:col-6">
          <label htmlFor="nome">RG</label>
          <div className="flex">
            <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
              {profissional.rg ? profissional.rg : "-"}
            </span>
          </div>
        </div>

        <div className="field col-12 lg:col-4 md:col-6">
          <label htmlFor="nome">Telefone</label>
          <div className="flex">
            <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
              {profissional.telefone ? profissional.telefone : "-"}
            </span>
          </div>
        </div>

        <div className="field col-12 lg:col-4 md:col-6">
          <label htmlFor="nome">Email</label>
          <div className="flex">
            <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
              {profissional.email ? profissional.email : "-"}
            </span>
          </div>
        </div>

      </div>

      <Divider />
      
      <div className="mb-2">
        <span style={{ fontSize: "1.2rem", fontWeight: "700", marginBottom: "0.5rem" }}>Endereço</span>
      </div>

      <div className="formgrid grid">

          <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="cep">CEP</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                {profissional.endereco?.cep ? profissional.endereco?.cep : "-"}
                </span>
              </div>
            </div>

          <div className="field col-12 lg:col-9 md:col-8">
              <label htmlFor="cep">Rua</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                {profissional.endereco?.rua ? profissional.endereco?.rua : "-"}
                </span>
              </div>
            </div>

          <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="cep">Número</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                {profissional.endereco?.numero ? profissional.endereco?.numero : "-"}
                </span>
              </div>
            </div>

          <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="cep">Bairro</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                {profissional.endereco?.bairro ? profissional.endereco?.bairro : "-"}
                </span>
              </div>
            </div>

          <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="cep">Cidade</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                {profissional.endereco?.cidade ? profissional.endereco?.cidade : "-"}
                </span>
              </div>
            </div>

          <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="cep">Estado</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                {profissional.endereco?.estado ? profissional.endereco?.estado : "-"}
                </span>
              </div>
            </div>

          <div className="field col-12 lg:col-12">
              <label htmlFor="cep">Complemento</label>
              <div className="flex">
                <span style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
                {profissional.endereco?.complemento ? profissional.endereco?.complemento : "-"}
                </span>
              </div>
            </div>

      </div>

    </TabPanel>

    <TabPanel header="Cadastro">

      <div className="mb-2">
        <span style={{ fontSize: "1.2rem", fontWeight: "700", marginBottom: "0.5rem" }}>Dados Principais</span>
      </div>

      <div className="formgrid grid">

        <div className="field col-12 md:col-8">
          <label htmlFor="nome">Nome</label>
          <InputText
            id="nome"
            value={formik.values.nome}
            maxLength={150}
            onChange={(e) => formik.setFieldValue("nome", e.target.value)}
            className={classNames({ "p-invalid": isFormFieldInvalid("nome") }, "w-full")}
          />
          {getFormErrorMessage("nome")}
        </div>

          <div className="field col-12 lg:col-4 md:col-4">
            <label htmlFor="dataNascimentoFor">Data de Nascimento</label>
            <Calendar
              id="dataNascimento"
              inputId="dataNascimentoFor"
              value={formik.values.dataNascimento !== null ? new Date(formik.values.dataNascimento) : null}
              onChange={(e) => {
                if (e.target.value === "") e.target.value = null;
                if (e.target.value !== null) {
                  formik.setFieldValue("dataNascimento", new Date(e.value).toISOString());
                } else {
                  formik.setFieldValue("dataNascimento", null);
                }
              }}
              showIcon
              className={classNames({ "p-invalid": isFormFieldInvalid("dataNascimento") }, "w-full")}
              touchUI={window.innerWidth < 768}
              showOnFocus={false}
              mask="99/99/9999"
            />
            {getFormErrorMessage("dataNascimento")}
          </div>

          <div className="field col-12 lg:col-4 md:col-6">
            <label htmlFor="cargoFor">Cargo</label>
            <Dropdown
              id="cargo"
              name="cargo"
              inputId="cargoFor"
              autoComplete="none"
              value={formik.values.cargo}
              onChange={(e) => formik.setFieldValue("cargo", e.target.value)}
              options={cargos}
              optionLabel="nome"
              placeholder="Selecione o cargo"
              className={classNames({ "p-invalid": isFormFieldInvalid("cargo") }, "w-full")}
            />
            {getFormErrorMessage("cargo")}
          </div>

          <div className="field col-12 lg:col-4 md:col-6">
            <label htmlFor="especializacao">Especialização</label>
            <InputText
              id="especializacao"
              value={formik.values.especializacao}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("especializacao", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("especializacao") }, "w-full")}
            />
            {getFormErrorMessage("especializacao")}
          </div>

          <div className="field col-12 lg:col-4 md:col-6">
            <label htmlFor="cpf">CPF</label>
            <InputMask
              id="cpf"
              value={formik.values.cpf}
              onChange={(e) => {
                if (e.target.value === "") e.target.value = null;
                formik.setFieldValue("cpf", e.target.value);
              }}
              mask="999.999.999-99"
              placeholder="999.999.999-99"
              className={classNames({ "p-invalid": isFormFieldInvalid("cpf") }, "w-full")}
            />
            {getFormErrorMessage("cpf")}
          </div>

          <div className="field col-12 lg:col-4 md:col-6">
            <label htmlFor="rg">RG</label>
            <InputMask
              id="rg"
              value={formik.values.rg}
              onChange={(e) => {
                if (e.target.value === "") e.target.value = null;
                formik.setFieldValue("rg", e.target.value);
              }}
              mask="99.999.999-*"
              placeholder="99.999.999-9"
              className="w-full"

            />
          </div>

          <div className="field col-12 lg:col-4 md:col-6">
            <label htmlFor="telefone">Telefone</label>
            <InputMask
              id="telefone"
              autoComplete="none"
              value={formik.values.telefone}
              onChange={(e) => {
                if (e.target.value === "") e.target.value = null;
                formik.setFieldValue("telefone", e.target.value);
              }}
              mask="(99) 99999-9999"
              placeholder="(99) 99999-9999"
              className={classNames({ "p-invalid": isFormFieldInvalid("telefone") }, "w-full")}
            />
            {getFormErrorMessage("telefone")}
          </div>
           
          <div className="field col-12 lg:col-4 md:col-6">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              autoComplete="email"
              value={formik.values.email}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("email") }, "w-full")}
            />
            {getFormErrorMessage("email")}
          </div>
          
        </div>

        <Divider />

        <div className="mb-2" toggleable collapsed={true}>
          <span style={{ fontSize: "1.2rem", fontWeight: "700", marginBottom: "0.5rem" }}>Endereço</span>
        </div>
          <div className="formgrid grid">
            <div className="field col-12 lg:col-3 md:col-4">
              <label htmlFor="cep">CEP</label>
              <InputMask
                id="cep"
                className="w-full"
                value={formik.values.endereco?.cep}
                onChange={(e) => {
                  if (e.target.value === "") e.target.value = null;
                  formik.setFieldValue("endereco.cep", e.target.value);
                }}
                mask="99999-999"
                placeholder="99999-999"
              />
            </div>
            <div className="field col-12 lg:col-9 md:col-8">
              <label htmlFor="rua">Rua</label>
              <InputText
                id="rua"
                className="w-full"
                value={formik.values.endereco?.rua}
                maxLength={150}
                onChange={(e) => formik.setFieldValue("endereco.rua", e.target.value)}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="numero">Número</label>
              <InputText
                id="numero"
                className="w-full"
                value={formik.values.endereco?.numero}
                maxLength={50}
                onChange={(e) => formik.setFieldValue("endereco.numero", e.target.value)}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="bairro">Bairro</label>
              <InputText
                id="bairro"
                className="w-full"
                value={formik.values.endereco?.bairro}
                maxLength={100}
                onChange={(e) => formik.setFieldValue("endereco.bairro", e.target.value)}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="cidade">Cidade</label>
              <InputText
                id="cidade"
                className="w-full"
                value={formik.values.endereco?.cidade}
                maxLength={100}
                onChange={(e) => formik.setFieldValue("endereco.cidade", e.target.value)}
              />
            </div>
            <div className="field col-12 lg:col-3 md:col-6">
              <label htmlFor="estado">Estado</label>
              <InputText
                id="estado"
                className="w-full"
                value={formik.values.endereco?.estado}
                maxLength={100}
                onChange={(e) => formik.setFieldValue("endereco.estado", e.target.value)}
              />
            </div>
            <div className="field col-12">
              <label htmlFor="complemento">Complemento</label>
              <InputTextarea
                id="complemento"
                className="w-full"
                value={formik.values.endereco?.complemento}
                maxLength={255}
                onChange={(e) => formik.setFieldValue("endereco.complemento", e.target.value)}
                rows={1}
                cols={30}
                autoResize
              />
            </div>
          </div>

        <Divider />

      <div className="buttons mt-2">
        <Button label="Salvar" icon="pi pi-save" severity="success" onClick={formik.handleSubmit} loading={loadingSubmit} />
      </div>

      </TabPanel>
    </TabView>
</>
  );
};

export default ProfissionalEdicao;
