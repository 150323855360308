import axiosInstance from "./baseService.service";

const API_URL = "agendamento/";

const agendamentoService = {
  cadastrar: async (agendamento) => {
    try {
      const response = await axiosInstance.post(API_URL + "cadastrarAgendamento", agendamento);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  alterar: async (agendamento) => {
    try {
      const response = await axiosInstance.post(API_URL + "alterarAgendamento", agendamento);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  alterarSituacao: async (alteracao) => {
    try {
      const response = await axiosInstance.post(API_URL + "alterarSituacaoAgendamento", alteracao);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  pesquisarAgendamentosPeriodo: async (dataInicial, dataFinal) => {
    try {
      const params = new URLSearchParams();

      params.append("dataInicial", dataInicial);
      params.append("dataFinal", dataFinal);

      const response = await axiosInstance.get(`${API_URL}pesquisarAgendamentosPeriodo?${params.toString()}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  pesquisarAgendamentosPerfil: async (dataInicial, dataFinal) => {
    try {
      const params = new URLSearchParams();

      params.append("dataInicial", dataInicial);
      params.append("dataFinal", dataFinal);

      const response = await axiosInstance.get(`${API_URL}pesquisarAgendamentosPerfil?${params.toString()}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  excluir: async (id) => {
    try {
      const params = new URLSearchParams();
      params.append("id", id);
      const response = await axiosInstance.post(`${API_URL}excluirAgendamento?${params.toString()}`, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default agendamentoService;
