import axiosInstance from "./baseService.service";

const API_URL = "responsavel/";

const responsavelService = {
  pesquisarResponsaveis: async (filtro, pacienteId) => {
    try {
      const params = new URLSearchParams();
      params.append("first", filtro.first);
      params.append("rows", filtro.rows);
      params.append("sortField", filtro.sortField);
      params.append("sortOrder", filtro.sortOrder);
      params.append("pacienteId", pacienteId);
      if (filtro.nome) params.append("nome", filtro.nome);

      const response = await axiosInstance.get(`${API_URL}pesquisarResponsaveis?${params.toString()}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  pesquisarResponsavel: async (id) => {
    try {
      const response = await axiosInstance.get(API_URL + "pesquisarResponsavel?id=" + id);
      return response;
    } catch (error) {
      throw error;
    }
  },

  cadastrarResponsavel: async (responsavel) => {
    try {
      const response = await axiosInstance.post(API_URL + "cadastrarResponsavel", responsavel);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  alterarResponsavel: async (responsavel) => {
    try {
      responsavel.agendamentos = null;
      const response = await axiosInstance.post(API_URL + "alterarResponsavel?id=" + responsavel.id, responsavel);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default responsavelService;
