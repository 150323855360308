import axiosInstance from "./baseService.service";

const API_URL = "movimentacaoFinanceiro/";

const contaService = {
  pesquisarMovimentacoes: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "pesquisarMovimentacoes");
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  pesquisarMovimentacao: async (id) => {
    try {
      const response = await axiosInstance.get(API_URL + "pesquisarMovimentacao?id=" + id);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  cadastrarMovimentacao: async (conta) => {
    try {
      const response = await axiosInstance.post(API_URL + "cadastrarMovimentacao", conta);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  alterarMovimentacao: async (conta) => {
    try {
      const response = await axiosInstance.post(API_URL + "alterarMovimentacao", conta);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default contaService;
