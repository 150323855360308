import authService from "../../../services/auth.service";

import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import React from "react";

import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Nome é obrigatório."),
  email: Yup.string().email("Email inválido.").required("Email é obrigatório."),
  senha: Yup.string()
    .required("Senha é obrigatória.")
    .min(6, "Senha muito curta."),
});

const PacienteEdicao = ({ mostrarMensagem }) => {
  const formik = useFormik({
    initialValues: {
      nome: "",
      email: "",
      senha: "",
    },
    validationSchema,
    onSubmit: (values) => {
      authService
        .register(values.nome, values.email, values.senha)
        .then((result) => {
          window.location.href = "/login";
        })
        .catch((error) => {
          if (error.response?.data != null) {
            mostrarMensagem(error.response.data, "error");
          } else {
            mostrarMensagem("Não foi possivel realizar o cadastro!", "error");
          }
        });
    },
  });

  const isFormFieldInvalid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? (
      <small className="p-error">{formik.errors[name]}</small>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Card title="Crie uma nova conta!">
        <div className="formgrid grid">
          <div className="field col-12">
            <label htmlFor="nome">Nome</label>
            <InputText
              id="nome"
              value={formik.values.nome}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("nome", e.target.value)}
              className={classNames(
                { "p-invalid": isFormFieldInvalid("nome") },
                "w-full"
              )}
            />
            {getFormErrorMessage("nome")}
          </div>

          <div className="field col-12">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              value={formik.values.email}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              className={classNames(
                { "p-invalid": isFormFieldInvalid("email") },
                "w-full"
              )}
            />
            {getFormErrorMessage("email")}
          </div>
          <div className="field col-12">
            <label htmlFor="senha">Senha</label>
            <Password
              feedback={false}
              id="senha"
              value={formik.values.senha}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("senha", e.target.value)}
              className={classNames(
                { "p-invalid": isFormFieldInvalid("senha") },
                "w-full"
              )}
            />
            {getFormErrorMessage("senha")}
          </div>
        </div>
        <div
          className="mt-2"
          style={{ display: "grid", justifyContent: "center" }}
        >
          <Button
            label="Registrar-se"
            icon="pi pi-save"
            severity="success"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
          />
          <Button
            label="Ir para login"
            onClick={() => {
              window.location.href = "/login";
            }}
            link
          ></Button>
        </div>
      </Card>
    </>
  );
};

export default PacienteEdicao;
