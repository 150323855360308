import authService from "../../../services/auth.service";

import React, { useState } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import Yup from "../../../util/CustomYup";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Campo obrigatório")
    .matches("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$", "Email inválido. (Ex: email@email.com)"),
});

const RecuperarConta = ({ mostrarMensagem }) => {
  const [loading, setLoading] = useState(false);
  const [emailEnviado, setEmailEnviado] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: null,
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      authService
        .recuperarConta(values.email)
        .then(() => {
          mostrarMensagem("O link de redefinição de senha foi enviado para seu e-mail.", "success");
          setLoading(false);
          setEmailEnviado(true);
        })
        .catch((error) => {
          mostrarMensagem(error, "error");
          setLoading(false);
        });
    },
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  return (
    <>
      <Card title="Redefinir sua senha">
        <div className="formgrid grid">
          <div className="field col-12">
            <label htmlFor="email">Email</label>
            <InputText
              id="email"
              name="email"
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("email") }, "w-full")}
              disabled={emailEnviado}
            />
            {getFormErrorMessage("email")}
          </div>
        </div>
        <div className="buttons">
          <Button
            label="Enviar Email de Redefinição"
            icon="pi pi-save"
            severity="success"
            type="submit"
            onClick={() => {
              formik.handleSubmit();
            }}
            loading={loading}
            disabled={emailEnviado}
          />
        </div>
        <div className="flex flex-wrap align-items-center justify-content-center">
          <Button
            label="Voltar para o Login"
            onClick={() => {
              window.location.href = "/login";
            }}
            link
          ></Button>
        </div>
      </Card>
    </>
  );
};

export default RecuperarConta;
