import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../services/auth.service";

const UnprotectedRoute = (props) => {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
    if (authService.checkIsTokenValid()) {
      setIsLoggedIn(true);
      return navigate("/home");
    }
    setIsLoggedIn(false);
    localStorage.removeItem("user");
  };

  useEffect(() => {
    checkUserToken();
  });

  return <React.Fragment>{!isLoggedIn ? props.children : null}</React.Fragment>;
};

export default UnprotectedRoute;
