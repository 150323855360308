import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import avisoService from "../../services/aviso.service";
import apoioService from "../../services/apoio.service";

import { useFormik } from "formik";
import { classNames } from "primereact/utils";

import { Dropdown } from "primereact/dropdown";
import Yup from "../../util/CustomYup";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { InputTextarea } from "primereact/inputtextarea";

const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Campo obrigatório."),
  mensagem: Yup.string().required("Campo obrigatório."),
  tipoMensagem: Yup.object().required("Campo obrigatório."),
});

const AvisoMensagemPesquisa = ({ mostrarMensagem }) => {
  const [loadingPesquisa, setLoadingPesquisa] = useState(false);
  const [loadingSalvar, setLoadingSalvar] = useState(false);
  const [avisos, setAvisos] = useState([]);
  const [tipoMensagem, setTipoMensagem] = useState([]);
  const [dialogEdicaoVisible, setDialogEdicaoVisible] = useState(false);
  const [dialogVisualizacaoVisible, setDialogVisualizacaoVisible] = useState(false);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    apoioService.listaTipoMensagem().then((result) => setTipoMensagem(result));
    pesquisarAvisos();
  }, []);

  const pesquisarAvisos = () => {
    setLoadingPesquisa(true);
    avisoService.pesquisarAvisosMensagem().then((result) => {
      setAvisos(result);
      setLoadingPesquisa(false);
    });
  };

  const formik = useFormik({
    initialValues: {
      id: 0,
      nome: "",
      tipoMensagem: null,
      tipoMensagemId: 1,
      mensagem: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setLoadingSalvar(true);
      if (values.id !== 0) {
        avisoService
          .alterarAvisoMensagem(values)
          .then((result) => {
            mostrarMensagem("Mensagem alterada com sucesso!", "success");
            pesquisarAvisos();
            setDialogEdicaoVisible(false);
            setLoadingSalvar(false);
          })
          .catch((error) => {
            mostrarMensagem(error, "error", null, true);
          });
      } else {
        avisoService
          .cadastrarAvisoMensagem(values)
          .then((result) => {
            mostrarMensagem("Mensagem cadastrada com sucesso!", "success");
            pesquisarAvisos();
            setDialogEdicaoVisible(false);
            setLoadingSalvar(false);
          })
          .catch((error) => {
            mostrarMensagem(error, "error", null, true);
          });
      }
    },
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  const confirmarExclusao = (id) => {
    confirmDialog({
      message: "Deseja realmente excluir o aviso?",
      header: "Excluir Aviso",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      defaultFocus: "accept",
      accept: () => {
        excluirAviso(id);
      },
    });
  };

  const excluirAviso = (id) => {
    avisoService
      .excluirAvisoMensagem(id)
      .then((result) => {
        mostrarMensagem("Aviso excluído com sucesso!", "success");
        pesquisarAvisos();
      })
      .catch((error) => {
        mostrarMensagem(error, "error");
      });
  };

  const formatarMensagem = (mensagem) => {
    return mensagem
      .replace("{nome-paciente}", "Nome do Paciente")
      .replace("{nome-profissional}", "Nome do Profissional")
      .replace("{data}", "10 de Janeiro de 2021")
      .replace("{hora}", "10:00");
  };

  return (
    <>
      <Card title="Mensagens de Aviso">
        <div className="grid">
          <div className="field col-fixed">
            <Button
              label="Novo"
              icon="pi pi-plus"
              severity="success"
              className="flex-none"
              onClick={() => {
                formik.resetForm();
                setDialogEdicaoVisible(true);
              }}
            />
          </div>
          <div className="field col" style={{ minWidth: "200px" }}>
            <div className="flex w-full">
              <span className="p-input-icon-left w-full mr-2">
                <i className="pi pi-search" />
                <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Procurar..."
                  className="w-full"
                />
              </span>

              <Button icon="pi pi-refresh" onClick={() => pesquisarAvisos()} className="flex-none" severity="info" />
            </div>
          </div>
        </div>
        <DataTable
          value={avisos}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          emptyMessage="Nenhum registro encontrado!"
          dataKey="id"
          filters={filters}
          globalFilterFields={["nome"]}
          loading={loadingPesquisa}
        >
          <Column field="nome" header="Nome" />
          <Column
            style={{ width: "1rem" }}
            field="edit"
            body={(aviso) => (
              <div className="buttons">
                <Button
                  icon="pi pi-pencil"
                  size="small"
                  onClick={() => {
                    formik.setValues(aviso);
                    formik.setFieldValue(
                      "tipoMensagem",
                      tipoMensagem.find((x) => x.id === aviso.tipoMensagemId)
                    );
                    setDialogEdicaoVisible(true);
                  }}
                />
                <Button
                  icon="pi pi-eye"
                  size="small"
                  onClick={() => {
                    formik.setValues(aviso);
                    setDialogVisualizacaoVisible(true);
                  }}
                />
                <Button
                  icon="pi pi-trash"
                  size="small"
                  className="p-button-danger"
                  onClick={() => {
                    confirmarExclusao(aviso.id);
                  }}
                />
              </div>
            )}
          />
        </DataTable>
      </Card>

      <Dialog
        header="Mensagem de Aviso"
        visible={dialogEdicaoVisible}
        style={{ width: "50vw" }}
        modal
        onHide={() => {
          setDialogEdicaoVisible(false);
          formik.resetForm();
        }}
        onShow={() => setLoadingSalvar(false)}
      >
        <div className="formgrid grid">
          <div className="field col-12">
            <label htmlFor="nome">Nome</label>
            <InputText
              id="nome"
              value={formik.values.nome}
              maxLength={150}
              onChange={(e) => formik.setFieldValue("nome", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("nome") }, "w-full")}
            />
            {getFormErrorMessage("nome")}
          </div>
          <div className="field col-12">
            <label htmlFor="tipoMensagem">Tipo</label>
            <Dropdown
              id="tipoMensagem"
              value={formik.values.tipoMensagem}
              onChange={(e) => {
                formik.setFieldValue("tipoMensagem", e.value);
                formik.setFieldValue("tipoMensagemId", e.value.id);
              }}
              options={tipoMensagem}
              optionLabel="descricao"
              placeholder="Selecione o tipo de mensagem"
              className={classNames({ "p-invalid": isFormFieldInvalid("tipoMensagem") }, "w-full")}
            />
            {getFormErrorMessage("tipoMensagem")}
          </div>
          <div className="field col-12">
            <label htmlFor="mensagem">Mensagem</label>
            <InputTextarea
              id="mensagem"
              value={formik.values.mensagem}
              maxLength={1000}
              onChange={(e) => formik.setFieldValue("mensagem", e.target.value)}
              className={classNames({ "p-invalid": isFormFieldInvalid("mensagem") }, "w-full")}
              autoResize
            />
            {getFormErrorMessage("mensagem")}
            <div className="field col-12">
              <div className="buttons mt-2">
                <Button
                  label="Paciente"
                  icon="pi pi-plus"
                  className="w-full"
                  severity="info"
                  size="small"
                  onClick={() => {
                    formik.setFieldValue("mensagem", formik.values.mensagem + "{nome-paciente}");
                  }}
                />
                <Button
                  label="Profissional"
                  icon="pi pi-plus"
                  className="w-full"
                  severity="info"
                  size="small"
                  onClick={() => {
                    formik.setFieldValue("mensagem", formik.values.mensagem + "{nome-profissional}");
                  }}
                />
                <Button
                  label="Data"
                  icon="pi pi-plus"
                  className="w-full"
                  severity="info"
                  size="small"
                  onClick={() => {
                    formik.setFieldValue("mensagem", formik.values.mensagem + "{data}");
                  }}
                />
                <Button
                  label="Hora"
                  icon="pi pi-plus"
                  className="w-full"
                  severity="info"
                  size="small"
                  onClick={() => {
                    formik.setFieldValue("mensagem", formik.values.mensagem + "{hora}");
                  }}
                />
              </div>
            </div>
            <div className="buttons">
              <Button
                label="Visualizar"
                icon="pi pi-eye"
                size="small"
                onClick={() => {
                  setDialogVisualizacaoVisible(true);
                }}
              />
            </div>
          </div>
        </div>
        <div className="buttons mt-2">
          <Button
            label="Salvar"
            icon="pi pi-save"
            severity="success"
            type="submit"
            loading={loadingSalvar}
            onClick={() => {
              formik.handleSubmit();
            }}
          />
          <Button
            label="Voltar"
            icon="pi pi-times"
            severity="secondary"
            onClick={() => {
              setDialogEdicaoVisible(false);
              formik.resetForm();
            }}
          />
        </div>
      </Dialog>

      <Dialog
        header="Visualizar Mensagem"
        visible={dialogVisualizacaoVisible}
        style={{ width: "50vw" }}
        modal
        onHide={() => setDialogVisualizacaoVisible(false)}
      >
        <div className="formgrid grid">
          <pre style={{ whiteSpace: "pre-wrap" }}>{formatarMensagem(formik.values.mensagem)}</pre>
        </div>
        <div className="buttons mt-2">
          <Button
            label="Voltar"
            icon="pi pi-times"
            severity="secondary"
            onClick={() => {
              setDialogVisualizacaoVisible(false);
            }}
          />
        </div>
      </Dialog>

      <ConfirmDialog />
    </>
  );
};

export default AvisoMensagemPesquisa;
