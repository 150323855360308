import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";

import { useFormik } from "formik";
import { classNames } from "primereact/utils";
import * as Yup from "yup";
import salaService from "../../services/sala.service";
import { InputNumber } from "primereact/inputnumber";

const validationSchema = Yup.object().shape({
  nome: Yup.string().required("Nome é obrigatório.").max(255, "Nome deve ter no máximo 255 caracteres."),
  descricao: Yup.string().max(255, "Descrição deve ter no máximo 255 caracteres."),
  capacidade: Yup.number()
    .required("Capacidade é obrigatória.")
    .min(1, "Capacidade deve ser maior que 0.")
    .max(99999, "Capacidade deve ser menor que 100000."),
});

const DialogSala = ({ dialogSalaVisible, setDialogSalaVisible, onSaveMetodo, salaAlteracao, mostrarMensagem }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: null,
      nome: "",
      descricao: "",
      capacidade: 0,
    },
    validationSchema,
    onSubmit: (values) => {
      setLoadingSubmit(true);
      if (salaAlteracao === null) {
        salaService
          .cadastrarSala(values)
          .then((response) => {
            onSaveMetodo();
            setDialogSalaVisible(false);
            setLoadingSubmit(false);
          })
          .catch((error) => {
            mostrarMensagem(error, "error", null, true);
            setLoadingSubmit(false);
          });
      } else {
        salaService.alterarSala(values).then((response) => {
          onSaveMetodo();
          setDialogSalaVisible(false);
          setLoadingSubmit(false);
        });
      }
    },
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name, formik) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  return (
    <>
      <Dialog
        header="Sala"
        visible={dialogSalaVisible}
        onHide={() => {
          setDialogSalaVisible(false);
        }}
        onShow={() => {
          formik.resetForm();
          if (salaAlteracao === null) {
            formik.setValues({
              id: null,
              nome: "",
              descricao: "",
              capacidade: 0,
            });
          } else {
            formik.setValues({
              id: salaAlteracao.id,
              nome: salaAlteracao.nome,
              descricao: salaAlteracao.descricao,
              capacidade: salaAlteracao.capacidade,
            });
          }
        }}
        style={{ width: "90vw" }}
      >
        <>
          <div className="formgrid grid">
            <div className="field col-12">
              <label htmlFor="nome">Nome</label>
              <InputText
                id="nome"
                value={formik.values.nome}
                maxLength={50}
                onChange={(e) => formik.setFieldValue("nome", e.target.value)}
                className={classNames({ "p-invalid": isFormFieldInvalid("nome") }, "w-full")}
              />
              {getFormErrorMessage("nome")}
            </div>
            <div className="field col-12">
              <label htmlFor="descricao">Descrição</label>
              <InputText
                id="descricao"
                value={formik.values.descricao}
                maxLength={255}
                onChange={(e) => formik.setFieldValue("descricao", e.target.value)}
                className={classNames({ "p-invalid": isFormFieldInvalid("descricao") }, "w-full")}
              />
              {getFormErrorMessage("descricao")}
            </div>

            <div className="field col-12">
              <label htmlFor="capacidade">Capacidade</label>
              <InputNumber
                id="capacidade"
                value={formik.values.capacidade}
                onValueChange={(e) => formik.setFieldValue("capacidade", e.value)}
                className={classNames({ "p-invalid": isFormFieldInvalid("capacidade") }, "w-full")}
                max={99999}
                min={1}
                maxFractionDigits={0}
                maxLength={5}
              />
              {getFormErrorMessage("capacidade")}
            </div>
          </div>
          <div className="buttons mt-2">
            <Button
              label={"Salvar"}
              icon="pi pi-save"
              severity="success"
              onClick={() => formik.handleSubmit()}
              type="submit"
              loading={loadingSubmit}
            />
            <Button label="Voltar" icon="pi pi-times" severity="secondary" onClick={() => setDialogSalaVisible(false)} />
          </div>
        </>
      </Dialog>
    </>
  );
};

export default DialogSala;
