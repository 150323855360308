import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { formatarData } from "../../../util/FormatarData";

import { useFormik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputTextarea } from "primereact/inputtextarea";
import { classNames } from "primereact/utils";
import { Fieldset } from "primereact/fieldset";
import { Divider } from "primereact/divider";

const DialogAgendamentoCancelar = ({ dialogVisible, setDialogVisible, agendamento, onSaveMetodo }) => {
  const [diffTime, setDiffTime] = useState({ diffHour: 1, diffMin: 0 });

  const formik = useFormik({
    initialValues: {
      id: "",
      motivoCancelamento: "",
      sessaoCobrada: false,
      sessaoReagendada: false,
      dataAgendamento: new Date(),
      horaInicial: "",
      horaFinal: "",
    },
    validate: (values) => {
      let errors = {};
      if (!values.motivoCancelamento) {
        errors.motivoCancelamento = "Campo obrigatório";
      }
      if (values.sessaoReagendada === true) {
        if (!values.dataAgendamento) {
          errors.dataAgendamento = "Campo obrigatório";
        }
        if (!values.horaInicial || values.horaInicial?.length === 0 || values.horaInicial.includes("_")) {
          errors.horaInicial = "Campo obrigatório";
        } else if (!/^([01]?[0-9]|2[0-4]):[0-5][0-9]$/.test(values.horaInicial)) {
          errors.horaInicial = "Formato inválido";
        }

        if (!values.horaFinal || values.horaInicial?.length === 0 || values.horaFinal.includes("_")) {
          errors.horaFinal = "Campo obrigatório";
        } else if (!/^([01]?[0-9]|2[0-4]):[0-5][0-9]$/.test(values.horaFinal)) {
          errors.horaFinal = "Formato inválido";
        }
      }
      return errors;
    },
    onSubmit: (values) => {
      onSaveMetodo(values);
      formik.resetForm();
    },
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  const onChangeHoraInicial = (e) => {
    formik.setFieldValue("horaInicial", e.target.value);

    const horaInicial = e.target.value.split(":");
    const horaFinal =
      !formik.values.horaFinal || formik.values.horaFinal.includes("_")
        ? e.target.value.split(":")
        : formik.values.horaFinal.split(":");

    horaFinal[0] = parseInt(horaInicial[0]) + diffTime.diffHour;
    horaFinal[1] = parseInt(horaInicial[1]) + diffTime.diffMin;

    formik.setFieldValue("horaFinal", `${horaFinal[0].toString().padStart(2, "0")}:${horaFinal[1].toString().padStart(2, "0")}`);
  };

  const onChangeHoraFinal = (e) => {
    formik.setFieldValue("horaFinal", e.target.value);
    formik.setFieldTouched("horaFinal", true);
  };

  return (
    <>
      <Dialog
        header={"Cancelar Agendamento " + formatarData(agendamento.dataAgendamento)}
        visible={dialogVisible}
        onHide={() => {
          formik.resetForm();
          setDialogVisible(false);
        }}
        onShow={() => {
          const dataAgendamento = new Date(agendamento.dataAgendamento);
          dataAgendamento.setDate(dataAgendamento.getDate() + 7);
          formik.setValues({
            id: agendamento.id,
            motivoCancelamento: "",
            sessaoCobrada: false,
            sessaoReagendada: false,
            dataAgendamento: dataAgendamento,
            horaInicial: agendamento.horaInicial,
            horaFinal: agendamento.horaFinal,
          });
        }}
        style={{ width: "85vw" }}
      >
        <div className="formgrid grid">
          <div className="field col-12">
            <label htmlFor="motivoCancelamento">Motivo</label>
            <InputTextarea
              id="motivoCancelamento"
              value={formik.values.motivoCancelamento}
              onChange={(e) => formik.setFieldValue("motivoCancelamento", e.target.value)}
              autoResize={true}
              maxLength={500}
              className={classNames(
                {
                  "p-invalid": isFormFieldInvalid("motivoCancelamento"),
                },
                "w-full"
              )}
            />
            {getFormErrorMessage("motivoCancelamento")}
          </div>
          <Divider />
          <div className="field col-12">
            <label htmlFor="cobra">Sessão será cobrada?</label>
            <div>
              <Dropdown
                id="cobra"
                value={formik.values.sessaoCobrada}
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                optionValue="value"
                onChange={(e) => formik.setFieldValue("sessaoCobrada", e.value)}
                placeholder="Selecione"
              />
            </div>
          </div>
          <div className="field col-12">
            <label htmlFor="reagendar">Reagendar sessão?</label>
            <div>
              <Dropdown
                id="reagendar"
                value={formik.values.sessaoReagendada}
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                optionValue="value"
                onChange={(e) => {
                  formik.setFieldValue("sessaoReagendada", e.value);
                }}
                placeholder="Selecione"
              />
            </div>
          </div>

          <Fieldset className={classNames("field col-12", { hidden: formik.values.sessaoReagendada !== true })}>
            <div className="formgrid grid">
              <div className="field col-12 lg:col-4">
                <label htmlFor="dataAgendamento">Data</label>
                <Calendar
                  id="dataAgendamento"
                  value={formik.values.dataAgendamento}
                  onChange={(e) => formik.setFieldValue("dataAgendamento", e.value)}
                  dateFormat="dd/mm/yy"
                  className={classNames(
                    {
                      "p-invalid": isFormFieldInvalid("dataAgendamento"),
                    },
                    "w-full"
                  )}
                  showIcon
                  touchUI={window.innerWidth < 768}
                  showOnFocus={false}
                  mask="99/99/9999"
                />
                {getFormErrorMessage("dataAgendamento")}
              </div>
              <div className="field col-6 lg:col-4">
                <label htmlFor="horaInicial">Horário Inicial</label>
                <InputMask
                  value={formik.values.horaInicial}
                  onChange={(e) => onChangeHoraInicial(e)}
                  mask="99:99"
                  placeholder="99:99"
                  className={classNames({ "p-invalid": isFormFieldInvalid("horaInicial") }, "w-full")}
                />
                {getFormErrorMessage("horaInicial")}
              </div>
              <div className="field col-6 lg:col-4">
                <label htmlFor="horaFinal">Horário Final</label>
                <InputMask
                  value={formik.values.horaFinal}
                  onChange={(e) => onChangeHoraFinal(e)}
                  onBlur={() => {
                    const horaInicial = formik.values.horaInicial.split(":");
                    const horaFinal =
                      !formik.values.horaFinal || formik.values.horaFinal.includes("_")
                        ? formik.values.horaInicial.split(":")
                        : formik.values.horaFinal.split(":");
                    const diffHour = parseInt(horaFinal[0]) - parseInt(horaInicial[0]);
                    const diffMin = parseInt(horaFinal[1]) - parseInt(horaInicial[1]);
                    setDiffTime({ diffHour: diffHour < 0 ? 1 : diffHour, diffMin: diffMin < 0 ? 0 : diffMin });
                  }}
                  mask="99:99"
                  placeholder="99:99"
                  className={classNames({ "p-invalid": isFormFieldInvalid("horaFinal") }, "w-full")}
                />
                {getFormErrorMessage("horaFinal")}
              </div>
            </div>
          </Fieldset>
        </div>
        <div className="buttons">
          <Button label="Confimar" severity="danger" icon="pi pi-times" type="submit" onClick={() => formik.handleSubmit()} />
          <Button label="Voltar" onClick={() => setDialogVisible(false)} className="p-button-secondary" />
        </div>
      </Dialog>
    </>
  );
};

export default DialogAgendamentoCancelar;
