import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useCallback, useEffect, useState } from "react";
import authService from "../../services/auth.service";
import salaService from "../../services/sala.service";
import DialogSala from "./dialogSala.component";

const SalaPesquisa = ({ mostrarMensagem }) => {
  const permissaoCadastro = authService.checkIfHasPermissao(["CAD_SALA"]);
  const [loading, setLoading] = useState(true);
  const [salas, setSalas] = useState([]);
  const [dialogSalaVisible, setDialogSalaVisible] = useState(false);
  const [salaAlteracao, setSalaAlteracao] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const [lazyState, setlazyState] = useState({
    first: 0,
    rows: 5,
    page: 0,
    sortField: "nome",
    sortOrder: true,
    filters: {
      nome: { value: "", matchMode: "contains" },
    },
  });

  const pesquisarSalas = useCallback(() => {
    setLoading(true);
    salaService
      .pesquisarSalas(lazyState)
      .then((result) => {
        setTotalRecords(result.total);
        setSalas(result.salas);
        setLoading(false);
      })
      .catch((error) => {
        mostrarMensagem(error, "error");
        setLoading(false);
      });
  }, [lazyState, mostrarMensagem]);

  useEffect(() => {
    pesquisarSalas();
  }, [pesquisarSalas]);

  const onPage = (event) => {
    setlazyState(event);
  };

  return (
    <>
      <Card title="Salas">
        <div className="grid">
          <div className="field col-fixed">
            <Button
              label="Novo"
              icon="pi pi-plus"
              severity="success"
              className="flex-none"
              onClick={() => {
                setSalaAlteracao(null);
                setDialogSalaVisible(true);
              }}
              disabled={!permissaoCadastro}
            />
          </div>
          <div className="field col" style={{ minWidth: "200px" }}>
            <div className="flex w-full">
              <span className="p-input-icon-left w-full mr-2">
                <i className="pi pi-search" />
                <InputText
                  onChange={(e) => setlazyState({ ...lazyState, nome: e.target.value })}
                  placeholder="Procurar..."
                  className="w-full"
                />
              </span>

              <Button icon="pi pi-refresh" onClick={() => pesquisarSalas()} className="flex-none" severity="info" />
            </div>
          </div>
        </div>
        <DataTable
          paginator
          rows={lazyState.rows}
          rowsPerPageOptions={[5, 10, 25, 50]}
          emptyMessage="Nenhum registro encontrado!"
          dataKey="id"
          loading={loading}
          first={lazyState.first}
          totalRecords={totalRecords}
          value={salas}
          onPage={onPage}
          lazy
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          filters={lazyState.filters}
        >
          <Column field="nome" header="Nome" />
          <Column field="capacidade" header="Capacidade" />
          <Column
            style={{ width: "1rem" }}
            field="edit"
            body={(rowData) => (
              <Button
                icon="pi pi-pencil"
                size="small"
                onClick={() => {
                  setSalaAlteracao(rowData);
                  setDialogSalaVisible(true);
                }}
              />
            )}
          />
        </DataTable>
      </Card>

      <DialogSala
        dialogSalaVisible={dialogSalaVisible}
        setDialogSalaVisible={setDialogSalaVisible}
        onSaveMetodo={() => {
          mostrarMensagem("Sala salva com sucesso!", "success");
          pesquisarSalas();
        }}
        salaAlteracao={salaAlteracao}
        mostrarMensagem={mostrarMensagem}
      />
    </>
  );
};

export default SalaPesquisa;
