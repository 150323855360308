import axiosInstance from "./baseService.service";

const API_URL = "sala/";

const salaService = {
  pesquisarSalas: async (filtro) => {
    try {
      const params = new URLSearchParams();
      params.append("first", filtro.first);
      params.append("rows", filtro.rows);
      params.append("sortField", filtro.sortField);
      params.append("sortOrder", filtro.sortOrder);
      if (filtro.nome) params.append("nome", filtro.nome);

      const response = await axiosInstance.get(`${API_URL}pesquisarSalas?${params.toString()}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  cadastrarSala: async (sala) => {
    try {
      const response = await axiosInstance.post(API_URL + "cadastrarSala", sala);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  alterarSala: async (sala) => {
    try {
      const response = await axiosInstance.post(API_URL + "alterarSala", sala);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default salaService;
