import React, { useCallback, useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import salaService from "../../../services/sala.service";

const DialogSala = ({ dialogSalaVisible, setDialogSalaVisibleVisible, onSelectMetodo }) => {
  const [salas, setSalas] = useState([]);
  const [loadingSalas, setLoadingSalas] = useState(true);
  const [totalSalas, setTotalRecords] = useState(0);
  const [lazyStateSalas, setlazyState] = useState({
    first: 0,
    rows: 5,
    page: 0,
    sortField: "nome",
    sortOrder: true,
    filters: {
      nome: { value: "", matchMode: "contains" },
    },
  });
  const onPage = (event) => {
    setlazyState(event);
  };

  const pesquisarSalas = useCallback(() => {
    setLoadingSalas(true);
    salaService.pesquisarSalas(lazyStateSalas).then((result) => {
      setTotalRecords(result.total);
      setSalas(result.salas);
      setLoadingSalas(false);
    });
  }, [lazyStateSalas]);

  useEffect(() => {
    pesquisarSalas();
  }, [pesquisarSalas]);

  return (
    <Dialog
      visible={dialogSalaVisible}
      onShow={() => {
        setlazyState({
          first: 0,
          rows: 5,
          page: 0,
          sortField: "nome",
          sortOrder: true,
          filters: {
            nome: { value: "", matchMode: "contains" },
          },
        });
      }}
      onHide={() => setDialogSalaVisibleVisible(false)}
      header="Salas"
      style={{ width: "90vw" }}
    >
      <div className="grid">
        <div className="field col" style={{ minWidth: "200px" }}>
          <div className="flex w-full">
            <span className="p-input-icon-left w-full mr-2">
              <i className="pi pi-search" />
              <InputText
                onChange={(e) => setlazyState({ ...lazyStateSalas, nome: e.target.value })}
                placeholder="Procurar..."
                className="w-full"
              />
            </span>

            <Button icon="pi pi-refresh" onClick={() => pesquisarSalas()} className="flex-none" severity="info" />
          </div>
        </div>
      </div>
      <DataTable
        paginator
        rows={lazyStateSalas.rows}
        rowsPerPageOptions={[5, 10, 50]}
        emptyMessage="Nenhum registro encontrado!"
        dataKey="id"
        loading={loadingSalas}
        first={lazyStateSalas.first}
        totalRecords={totalSalas}
        value={salas}
        onPage={onPage}
        lazy
        sortField={lazyStateSalas.sortField}
        sortOrder={lazyStateSalas.sortOrder}
        filters={lazyStateSalas.filters}
        selectionMode="single"
        onSelectionChange={(e) => {
          setDialogSalaVisibleVisible(false);
          onSelectMetodo(e.value);
        }}
      >
        <Column field="nome" header="Nome" />
        <Column field="capacidade" header="Capacidade" />
        <Column
          style={{ width: "1rem" }}
          body={(rowData) => (
            <Button
              icon="pi pi-check"
              size="small"
              severity="success"
              onClick={() => {
                setDialogSalaVisibleVisible(false);
                onSelectMetodo(rowData);
              }}
            />
          )}
        />
      </DataTable>
    </Dialog>
  );
};

export default DialogSala;
