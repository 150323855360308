import contaService from "../../../../services/conta.service";

import { FilterMatchMode } from "primereact/api";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import DialogConta from "./dialogConta.component";

import authService from "../../../../services/auth.service";

const ContaPrincipal = ({ mostrarMensagem }) => {
  const permissaoCadastro = authService.checkIfHasPermissao(["ADM_FINANCEIRO_CONTA"]);
  const [loading, setLoading] = useState(true);

  const [contas, setContas] = useState([]);
  const [contaId, setContaId] = useState({});
  const [dialogContaVisible, setDialogContaVisible] = useState(false);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  useEffect(() => {
    load();
  }, []);

  const load = () => {
    contaService.resgatarContas().then((result) => {
      setContas(result);
      setLoading(false);
    });
  };

  const converterValor = (saldo) => {
    return (
      "R$ " +
      saldo.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    );
  };

  return (
    <>
      <Card title="Contas">
        <div className="grid">
          <div className="field col-fixed">
            <Button
              label="Novo"
              icon="pi pi-plus"
              severity="success"
              className="flex-none"
              onClick={() => {
                setContaId(null);
                setDialogContaVisible(true);
              }}
              disabled={!permissaoCadastro}
            />
          </div>
          <div className="field col" style={{ minWidth: "200px" }}>
            <div className="flex w-full">
              <span className="p-input-icon-left w-full mr-2">
                <i className="pi pi-search" />
                <InputText
                  value={globalFilterValue}
                  onChange={onGlobalFilterChange}
                  placeholder="Procurar..."
                  className="w-full"
                />
              </span>

              <Button icon="pi pi-refresh" onClick={() => load()} className="flex-none" severity="info" />
            </div>
          </div>
        </div>
        <DataTable
          value={contas}
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
          emptyMessage="Nenhum registro encontrado!"
          dataKey="id"
          filters={filters}
          globalFilterFields={["nome", "tipoConta.descricao"]}
          loading={loading}
        >
          <Column field="nome" header="Nome" />
          <Column field="tipoContaDescricao" header="Tipo" />
          <Column field={(field) => converterValor(field.saldo)} header="Saldo" />
          <Column
            style={{ width: "1rem" }}
            field="edit"
            body={(rowData) => (
              <Button
                icon="pi pi-pencil"
                size="small"
                onClick={() => {
                  setContaId(rowData.id);
                  setDialogContaVisible(true);
                }}
              />
            )}
          />
        </DataTable>
      </Card>

      <DialogConta
        dialogContaVisible={dialogContaVisible}
        setDialogContaVisible={setDialogContaVisible}
        onSaveMetodo={() => {
          mostrarMensagem("Conta salva com sucesso!", "success");
          load();
        }}
        contaId={contaId}
        mostrarMensagem={mostrarMensagem}
      />
    </>
  );
};

export default ContaPrincipal;
