import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "primeicons/primeicons.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { locale, addLocale } from "primereact/api";

const config = require(`./util/locale-pt.json`);
addLocale("pt", config);
locale("pt");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
);

reportWebVitals();
