import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../services/auth.service";

const ProtectedRoute = (props) => {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkUserToken = () => {
    if (!authService.checkIsTokenValid()) {
      setIsLoggedIn(false);
      return navigate("/login");
    }
    setIsLoggedIn(true);
  };

  const checkUserPermissao = () => {
    if (props.permissoes && !authService.checkIfHasPermissao(props.permissoes)) {
      setIsLoggedIn(false);
      return navigate("/home");
    }
  };

  const alterarTitulo = () => {
    if (props.titulo) document.title = "PsiQ - " + props.titulo;
  };

  useEffect(() => {
    checkUserToken();
    checkUserPermissao();
    alterarTitulo();
  });

  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>;
};

export default ProtectedRoute;
