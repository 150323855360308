import axios from "axios";
import config from "../config";
import authHeader from "./auth-header";

// Create an instance of Axios
const axiosInstance = axios.create({
  baseURL: config.apiEndpoint,
  // Other configuration options
  headers: authHeader(),
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // You can modify config here before sending the request
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // If the response is successful, return it
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401 && window.location.pathname !== "/login") {
      localStorage.removeItem("user");
      window.location.href = "/login";
      return;
    }
    if (error.response && error.response.status === 400) {
      return Promise.reject(error.response.data);
    }
    if (error.response && error.response.status === 403) {
      window.location.href = "/home";
      return;
    }
    // Return any other errors
    return Promise.reject(error);
  }
);

export default axiosInstance;
