import axiosInstance from "./baseService.service";

const API_URL = "dashboard/";

const dashboardService = {
  gerarHome: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "inicio");
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default dashboardService;
