import axiosInstance from "./baseService.service";

const API_URL = "empresa/usuario/";

const empresaUsuarioService = {
  pesquisarTodos: async (filtro) => {
    try {
      const response = await axiosInstance.post(API_URL + "pesquisarUsuarios", filtro);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  cadastrarUsuario: async (usuario) => {
    try {
      const response = await axiosInstance.post(API_URL + "cadastrarUsuario", usuario);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  alterarUsuario: async (usuario) => {
    try {
      const response = await axiosInstance.post(API_URL + "alterarUsuario", usuario);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  alterarSenha: (senhas, id) => {
    try {
      const response = axiosInstance.post(API_URL + "alterarSenhaUsuario", {
        id: id,
        senhaNova: senhas.senhaNova,
        senhaNovaConfirmacao: senhas.senhaNovaConfirmacao,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default empresaUsuarioService;
