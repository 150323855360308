import jwt_decode from "jwt-decode";
import axiosInstance from "./baseService.service";

const API_URL = "auth/";

const authService = {
  login: (username, password) => {
    try {
      const response = axiosInstance
        .post(API_URL + "login", {
          username,
          password,
        })
        .then((response) => {
          if (response.data.token) {
            localStorage.setItem("user", JSON.stringify(response.data));
          }
          return response.data;
        });
      return response;
    } catch (error) {
      throw error;
    }
  },

  logout: () => {
    localStorage.removeItem("user");
  },
  register: (name, email, password) => {
    try {
      const response = axiosInstance.post(API_URL + "register", {
        name: name,
        username: email,
        email: email,
        password: password,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },

  getUserInfo: () => {
    try {
      const response = axiosInstance
        .get(API_URL + "userinfo")
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return null;
        });
      return response;
    } catch (error) {
      throw error;
    }
  },

  checkIsTokenValid: () => {
    const localUser = JSON.parse(localStorage.getItem("user"));
    if (localUser) {
      try {
        const decodedToken = jwt_decode(localUser.token);
        const currentTime = Date.now() / 1000;
        if (decodedToken.exp > currentTime) {
          return true;
        }
      } catch (err) {
        return false;
      }
    }
    return false;
  },

  checkIfHasPermissao: (listaPermissoes) => {
    try {
      const localUser = JSON.parse(localStorage.getItem("user"));

      if (localUser) {
        const permissoesUsuario = localUser.permissoes;

        for (const element of listaPermissoes) {
          if (permissoesUsuario.some((perm) => perm.roleCode === element || perm.roleCode === "ADM_SUPER")) {
            return true;
          }
        }
      }
    } catch (err) {
      console.error(err);
    }

    return false;
  },
  alterarSenha: (senhas) => {
    try {
      const response = axiosInstance.post(API_URL + "alterarSenha", {
        senhaAntiga: senhas.senhaAntiga,
        senhaNova: senhas.senhaNova,
        senhaNovaConfirmacao: senhas.senhaNovaConfirmacao,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  recuperarConta: (email) => {
    try {
      const response = axiosInstance.post(API_URL + "emailRecuperarConta", {
        email: email,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  alterarSenhaToken: (senha, token) => {
    try {
      const response = axiosInstance.post(API_URL + "alterarSenhaToken", {
        senha: senha,
        tokenEmail: token,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default authService;
