import axiosInstance from "./baseService.service";

const API_URL = "perfil/";

const perfilService = {
  getPerfil: async (id) => {
    try {
      const response = await axiosInstance.get(API_URL + "pesquisarPerfil");
      return response;
    } catch (error) {
      throw error;
    }
  },

  editPerfil: async (perfil) => {
    try {
      const response = await axiosInstance.post(API_URL + "alterarPerfil", perfil);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default perfilService;
