import React from "react";

import { Skeleton } from "primereact/skeleton";

const DialogContaLoading = () => {
  return (
    <>
      <div className="formgrid grid">
        <div className="field col-12 mb-3">
          <Skeleton height="15px" width="60px" className="mb-1" />
          <Skeleton height="35px" />
        </div>
        <div className="field col-12 lg:col-6 mb-3">
          <Skeleton height="15px" width="60px" className="mb-1" />
          <Skeleton height="35px" />
        </div>
        <div className="field col-12 lg:col-6 mb-3">
          <Skeleton height="15px" width="60px" className="mb-1" />
          <Skeleton height="35px" />
        </div>
        <div className="field col-12 mb-3">
          <Skeleton height="15px" width="60px" className="mb-1" />
          <Skeleton height="35px" />
        </div>
      </div>
      <div style={{ height: "50px" }}></div>
    </>
  );
};

export default DialogContaLoading;
