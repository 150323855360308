import { Button } from "primereact/button";
import { Menu } from "primereact/menu";
import { Menubar } from "primereact/menubar";
import React, { useRef } from "react";
import authService from "../../services/auth.service";

export default function Header() {
  const isLogado = authService.checkIsTokenValid();
  const menuUsuario = useRef(null);

  const cadastros = {
    label: "Cadastros",
    icon: "pi pi-pencil",
    visible: isLogado && authService.checkIfHasPermissao(["PES_PACIENTE", "PES_PROFISSIONAL", "PES_SALA"]),
    items: [
      {
        label: "Paciente",
        icon: "pi pi-circle",
        command: () => (window.location.href = "/paciente"),
        visible: isLogado && authService.checkIfHasPermissao(["PES_PACIENTE"]),
      },
      {
        label: "Profissional",
        icon: "pi pi-circle-fill",
        command: () => (window.location.href = "/profissional"),
        visible: isLogado && authService.checkIfHasPermissao(["PES_PROFISSIONAL"]),
      },
      {
        label: "Salas",
        icon: "pi pi-building",
        command: () => (window.location.href = "/sala"),
        visible: isLogado && authService.checkIfHasPermissao(["PES_SALA"]),
      },
    ],
  };

  const financeiro = {
    label: "Financeiro",
    icon: "pi pi-dollar",
    visible: isLogado && authService.checkIfHasPermissao(["ADM_FINANCEIRO_CONTA", "PES_FINANCEIRO_MOVIMENTACAO"]),
    items: [
      {
        label: "Comissão",
        icon: "pi pi-book",
        command: () => (window.location.href = "/comissao"),
        visible: isLogado && authService.checkIfHasPermissao(["ADM_SUPER"]),
      },
      {
        label: "Conta",
        icon: "pi pi-book",
        command: () => (window.location.href = "/financeiro/conta"),
        visible: isLogado && authService.checkIfHasPermissao(["ADM_FINANCEIRO_CONTA"]),
      },
      {
        label: "Movimentação",
        icon: "pi pi-money-bill",
        command: () => (window.location.href = "/financeiro/movimentacao"),
        visible: isLogado && authService.checkIfHasPermissao(["PES_FINANCEIRO_MOVIMENTACAO"]),
      },
    ],
  };

  const apoio = {
    label: "Apoio",
    icon: "pi pi-question-circle",
    visible: isLogado && authService.checkIfHasPermissao(["PES_AVISO_MENSAGEM"]),
    items: [
      {
        label: "Aviso Mensagem",
        icon: "pi pi-circle",
        command: () => (window.location.href = "/aviso"),
        visible: isLogado && authService.checkIfHasPermissao(["PES_AVISO_MENSAGEM"]),
      },
    ],
  };

  const items = [
    {
      label: "Home",
      icon: "pi pi-home",
      command: () => (window.location.href = "/"),
      visible: !isLogado,
    },
    {
      label: "Home",
      icon: "pi pi-home",
      command: () => (window.location.href = "/home"),
      visible: isLogado,
    },
    cadastros,
    apoio,
    {
      label: "Agendamento",
      icon: "pi pi-calendar",
      command: () => (window.location.href = "/agendamento"),
      visible: isLogado && authService.checkIfHasPermissao(["PES_AGENDAMENTO"]),
    },
    financeiro,
  ];

  const itemsUsuario = [
    {
      label: "Menu",
      items: [
        {
          label: "Perfil",
          icon: "pi pi-user",
          command: () => (window.location.href = "/perfil"),
        },
      ],
    },
    {
      separator: isLogado && authService.checkIfHasPermissao(["ADM_EMPRESA_USUARIO"]),
    },
    {
      label: isLogado && authService.checkIfHasPermissao(["ADM_EMPRESA_USUARIO"]) ? "Empresa" : "",
      visible: isLogado && authService.checkIfHasPermissao(["ADM_EMPRESA_USUARIO"]),
      items: [
        {
          label: "Usuários",
          icon: "pi pi-users",
          command: () => (window.location.href = "/empresa/usuario"),
          visible: isLogado && authService.checkIfHasPermissao(["ADM_EMPRESA_USUARIO"]),
        },
      ],
    },
    {
      separator: true,
    },
    {
      label: "Sair",
      icon: "pi pi-sign-out",
      command: () => {
        authService.logout();
        window.location.href = "/home";
      },
    },
  ];

  const start = (
    <a href="/" style={{ textDecoration: "none", color: "black" }}>
      <span style={{ fontSize: "28px", fontWeight: "bold", margin: "5px" }}>PsiQ</span>
    </a>
  );
  const end = isLogado ? (
    <>
      <Menu model={itemsUsuario} popup ref={menuUsuario} id="popup_menu_right" popupAlignment="right" />
      <Button
        icon="pi pi-user"
        className="mr-2"
        onClick={(event) => menuUsuario.current.toggle(event)}
        aria-controls="popup_menu_right"
        aria-haspopup
        rounded
        severity="info"
      />
    </>
  ) : (
    <>
      <Button
        label="Registrar-se"
        icon="pi pi-user-plus"
        severity="info"
        onClick={() => (window.location.href = "/registrar")}
        link
      />
      <Button label="Entrar" icon="pi pi-sign-in" severity="success" onClick={() => (window.location.href = "/login")} />
    </>
  );

  return (
    <div className="background-header">
      <Menubar
        model={items}
        start={start}
        end={end}
        style={{ borderRadius: "0px", border: "none", margin: "auto auto 10px auto" }}
      />
    </div>
  );
}
