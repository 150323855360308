import axiosInstance from "./baseService.service";

const API_URL = "cargo/";

const cargoService = {
  getAllCargos: async () => {
    try {
      const response = await axiosInstance.get(API_URL + "pesquisarCargos");
      return response;
    } catch (error) {
      throw error;
    }
  },
};

export default cargoService;
