import agendamentoService from "../../../services/agendamento.service";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react";

import { useFormik } from "formik";
import { Divider } from "primereact/divider";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import * as Yup from "yup";
import authService from "../../../services/auth.service";
import DialogPaciente from "../common/dialogPaciente.component";
import DialogProfissional from "../common/dialogProfissional.component";
import DialogSala from "../common/dialogSala.component";

const validationSchema = Yup.object().shape({
  paciente: Yup.object().required("Paciente é obrigatório."),
  profissional: Yup.object().required("Profissional é obrigatório."),
  dataAgendamento: Yup.string().required("Data de Agendamento é obrigatória."),
  horaInicial: Yup.string()
    .required("Horário de início é obrigatório.")
    .matches(/^([01]?[0-9]|2[0-4]):[0-5][0-9]$/, "Hora inválida"),
  horaFinal: Yup.string()
    .required("Horário de término é obrigatório.")
    .matches(/^([01]?[0-9]|2[0-4]):[0-5][0-9]$/, "Hora inválida"),
  valor: Yup.number().required("Valor é obrigatório.").min(0, "Valor não pode ser negativo."),
});

const DialogAgendamentoCadastrar = ({
  dialogAgendamentoVisible,
  setDialogAgendamentoVisible,
  onSaveMetodo,
  paciente,
  profissional,
  mostrarMensagem,
  dataSelecionada,
}) => {
  const permissaoPesquisaSala = authService.checkIfHasPermissao(["PES_SALA"]);
  const permissaoPesquisaPaciente = authService.checkIfHasPermissao(["PES_PACIENTE"]);
  const permissaoPesquisaProfissional = authService.checkIfHasPermissao(["PES_PROFISSIONAL"]);

  const [dialogPacienteVisible, setDialogPacienteVisible] = useState(false);
  const [dialogProfissionalVisible, setDialogProfissionalVisible] = useState(false);
  const [dialogSalaVisible, setDialogSalaVisible] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [diffTime, setDiffTime] = useState({ diffHour: 1, diffMin: 0 });

  const formik = useFormik({
    initialValues: {
      id: "",
      paciente: null,
      pacienteId: null,
      profissional: null,
      profissionalId: null,
      dataAgendamento: null,
      horaInicial: null,
      horaFinal: null,
      sala: null,
      salaId: null,
      valor: null,
      presencial: true,
      repetirAgendamento: false,
      periodoRepeticao: 7,
      quantidadeRepeticao: 4,
      dataFinalRepeticao: null,
    },
    validationSchema,
    validate: (values) => {
      if (values.presencial && !values.sala) return { sala: "Sala é obrigatória." };
    },
    onSubmit: (values) => {
      setLoadingSubmit(true);
      agendamentoService
        .cadastrar(values)
        .then((result) => {
          onSaveMetodo(formik.values.dataAgendamento);
          setDialogAgendamentoVisible(false);
          cleanFormik();
          setLoadingSubmit(false);
          formik.resetForm();
        })
        .catch((error) => {
          setLoadingSubmit(false);
          mostrarMensagem(error, "error", null, true);
        });
    },
  });

  const cleanFormik = () => {
    formik.setValues({
      id: "",
      paciente: null,
      profissional: null,
      dataAgendamento: new Date(),
      horaInicial: null,
      horaFinal: null,
      sala: null,
      salaId: null,
      valor: null,
      presencial: true,
      repetirAgendamento: false,
      quantidadeRepeticao: 4,
      periodoRepeticao: 7,
      dataFinalRepeticao: null,
    });
    formik.setTouched({});
  };

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <></>;
  };

  const isFormSalaInvalid = () => {
    return !!(formik.touched.sala && formik.errors.sala);
  };

  const getFormSalaMessage = () => {
    return isFormSalaInvalid() ? <small className="p-error">{formik.errors.sala}</small> : <></>;
  };

  const onChangeHoraInicial = (e) => {
    formik.setFieldValue("horaInicial", e.target.value);

    const horaInicial = e.target.value.split(":");
    const horaFinal =
      !formik.values.horaFinal || formik.values.horaFinal.includes("_")
        ? e.target.value.split(":")
        : formik.values.horaFinal.split(":");

    horaFinal[0] = parseInt(horaInicial[0]) + diffTime.diffHour;
    horaFinal[1] = parseInt(horaInicial[1]) + diffTime.diffMin;

    formik.setFieldValue("horaFinal", `${horaFinal[0].toString().padStart(2, "0")}:${horaFinal[1].toString().padStart(2, "0")}`);
  };

  const onChangeHoraFinal = (e) => {
    formik.setFieldValue("horaFinal", e.target.value);
    formik.setFieldTouched("horaFinal", true);
  };

  const abrirDialogSalas = () => {
    if (!permissaoPesquisaSala) {
      alert("Você não tem permissão para pesquisar salas!");
      return;
    }
    setDialogSalaVisible(true);
  };

  const abrirDialogPaciente = () => {
    if (paciente) return;
    if (!permissaoPesquisaPaciente) {
      alert("Você não tem permissão para pesquisar pacientes!");
      return;
    }
    setDialogPacienteVisible(true);
  };

  const abrirDialogProfissional = () => {
    if (profissional) return;
    if (!permissaoPesquisaProfissional) {
      alert("Você não tem permissão para pesquisar profissionais!");
      return;
    }
    setDialogProfissionalVisible(true);
  };

  return (
    <>
      <Dialog
        header="Agendamento"
        visible={dialogAgendamentoVisible}
        onShow={() => {
          cleanFormik();
          if (paciente) {
            formik.setFieldValue("paciente", paciente);
            formik.setFieldValue("pacienteId", paciente?.id);
          }
          console.log(profissional);
          if (profissional) {
            formik.setFieldValue("profissional", profissional);
            formik.setFieldValue("profissionalId", profissional?.id);
          }
          if (dataSelecionada?.horaInicial && dataSelecionada?.horaFinal) {
            formik.setFieldValue("horaInicial", dataSelecionada?.horaInicial);
            formik.setFieldValue("horaFinal", dataSelecionada?.horaFinal);
          }
          if (
            dataSelecionada &&
            dataSelecionada?.data &&
            dataSelecionada?.data.ano &&
            dataSelecionada?.data.mes &&
            dataSelecionada?.data.dia
          ) {
            formik.setFieldValue(
              "dataAgendamento",
              new Date(dataSelecionada?.data.ano, dataSelecionada?.data.mes - 1, dataSelecionada?.data.dia)
            );
          }
        }}
        onHide={() => {
          cleanFormik();
          setDialogAgendamentoVisible(false);
        }}
        style={{ width: "90vw" }}
      >
        <div className="formgrid grid">
          <div className="field col-12">
            <label htmlFor="paciente">Paciente</label>
            <div className="flex" onClick={() => abrirDialogPaciente()}>
              <InputText
                value={formik.values.paciente?.nome}
                className={classNames(
                  {
                    "p-invalid": isFormFieldInvalid("paciente"),
                  },
                  "w-full flex-grow-1 mr-2"
                )}
                style={{ cursor: "pointer" }}
                readOnly
              />
              <Button icon="pi pi-search" className={classNames("flex-none", paciente ? "hidden" : "")} />
            </div>
            {getFormErrorMessage("paciente")}
          </div>
          <div className="field col-12">
            <label htmlFor="profissional">Profissional</label>
            <div className="flex" onClick={() => abrirDialogProfissional()}>
              <InputText
                value={formik.values.profissional?.nome}
                className={classNames(
                  {
                    "p-invalid": isFormFieldInvalid("profissional"),
                  },
                  "w-full flex-grow-1 mr-2"
                )}
                style={{ cursor: "pointer" }}
                readOnly
              />
              <Button icon="pi pi-search" className={classNames("flex-none", profissional ? "hidden" : "")} />
            </div>
            {getFormErrorMessage("profissional")}
          </div>
          <div className="field col-12 lg:col-4">
            <label htmlFor="dataAgendamento">Data</label>
            <Calendar
              id="dataAgendamento"
              value={formik.values.dataAgendamento}
              onChange={(e) => formik.setFieldValue("dataAgendamento", e.value)}
              dateFormat="dd/mm/yy"
              className={classNames(
                {
                  "p-invalid": isFormFieldInvalid("dataAgendamento"),
                },
                "w-full"
              )}
              showIcon
              touchUI={window.innerWidth < 768}
              showOnFocus={false}
              mask="99/99/9999"
            />
            {getFormErrorMessage("dataAgendamento")}
          </div>
          <div className="field col-6 lg:col-4">
            <label htmlFor="horaInicial">Horário Inicial</label>
            <InputMask
              value={formik.values.horaInicial}
              onChange={(e) => onChangeHoraInicial(e)}
              mask="99:99"
              placeholder="99:99"
              className={classNames({ "p-invalid": isFormFieldInvalid("horaInicial") }, "w-full")}
            />
            {getFormErrorMessage("horaInicial")}
          </div>
          <div className="field col-6 lg:col-4">
            <label htmlFor="horaFinal">Horário Final</label>
            <InputMask
              value={formik.values.horaFinal}
              onChange={(e) => onChangeHoraFinal(e)}
              onBlur={() => {
                const horaInicial = formik.values.horaInicial.split(":");
                const horaFinal =
                  !formik.values.horaFinal || formik.values.horaFinal.includes("_")
                    ? formik.values.horaInicial.split(":")
                    : formik.values.horaFinal.split(":");
                const diffHour = parseInt(horaFinal[0]) - parseInt(horaInicial[0]);
                const diffMin = parseInt(horaFinal[1]) - parseInt(horaInicial[1]);
                setDiffTime({ diffHour: diffHour < 0 ? 1 : diffHour, diffMin: diffMin < 0 ? 0 : diffMin });
              }}
              mask="99:99"
              placeholder="99:99"
              className={classNames({ "p-invalid": isFormFieldInvalid("horaFinal") }, "w-full")}
            />
            {getFormErrorMessage("horaFinal")}
          </div>
          <div className="field col-12">
            <label htmlFor="valor">Valor</label>
            <InputNumber
              id="valor"
              name="valor"
              value={formik.values.valor}
              onValueChange={(e) => formik.setFieldValue("valor", e.value)}
              mode="currency"
              currency="BRL"
              locale="pt-BR"
              className={classNames({ "p-invalid": isFormFieldInvalid("valor") }, "w-full")}
            />
            {getFormErrorMessage("valor")}
          </div>
          <Divider />
          <div className="field col-12 md:col-5">
            <label htmlFor="presencial">Como os eventos acontecerão?</label>
            <Dropdown
              id="presencial"
              name="presencial"
              inputId="presencialFor"
              value={formik.values.presencial}
              onChange={(e) => formik.setFieldValue("presencial", e.value)}
              options={[
                { label: "Presencial", value: true },
                { label: "Online", value: false },
              ]}
              optionValue="value"
              placeholder="Selecione"
              className={classNames({ "p-invalid": isFormFieldInvalid("presencial") }, "w-full")}
            />
            {getFormErrorMessage("presencial")}
          </div>
          <div className={classNames("field col-12 md:col-7", { hidden: formik.values.presencial })} />
          <div className={classNames("field col-12 md:col-7", { hidden: !formik.values.presencial })}>
            <label htmlFor="sala">Sala</label>
            <div className="flex" onClick={() => abrirDialogSalas()}>
              <InputText
                value={formik.values.sala?.nome}
                className={classNames(
                  {
                    "p-invalid": isFormFieldInvalid("sala"),
                  },
                  "w-full flex-grow-1 mr-2"
                )}
                style={{ cursor: "pointer" }}
                readOnly
              />
              <Button icon="pi pi-search" className={classNames("flex-none")} />
            </div>
            {getFormSalaMessage("sala")}
          </div>
          <Divider />
          <div className="field col-12 md:col-4">
            <label htmlFor="repetirAgendamento">Repetir Agendamento?</label>
            <div className="w-full">
              <Dropdown
                id="repetirAgendamento"
                name="repetirAgendamento"
                inputId="repetirAgendamentoFor"
                value={formik.values.repetirAgendamento}
                onChange={(e) => formik.setFieldValue("repetirAgendamento", e.value)}
                options={[
                  { label: "Não", value: false },
                  { label: "Sim", value: true },
                ]}
                optionValue="value"
                placeholder="Selecione"
                className={classNames("w-full")}
              />
            </div>
          </div>
          <div className={classNames("field col-12 md:col-4 sm:col-6", { hidden: !formik.values.repetirAgendamento })}>
            <label htmlFor="periodoRepeticao">Intervalo</label>
            <Dropdown
              id="periodoRepeticao"
              name="periodoRepeticao"
              inputId="periodoRepeticao"
              value={formik.values.periodoRepeticao}
              onChange={(e) => formik.setFieldValue("periodoRepeticao", e.value)}
              options={[
                { label: "Semanal", value: 7 },
                { label: "Quinzenal", value: 14 },
                { label: "Mensal", value: 28 },
              ]}
              optionValue="value"
              placeholder="Selecione"
              className={classNames("w-full")}
            />
          </div>
          <div className={classNames("field col-12 md:col-4 sm:col-6", { hidden: !formik.values.repetirAgendamento })}>
            <label htmlFor="quantidadeRepeticao">Quantidade</label>
            <div>
              <InputNumber
                id="quantidadeRepeticao"
                name="quantidadeRepeticao"
                value={formik.values.quantidadeRepeticao}
                onValueChange={(e) => formik.setFieldValue("quantidadeRepeticao", e.value)}
                className={classNames("w-full")}
              />
            </div>
          </div>
        </div>

        <div className="buttons mt-2">
          <Button
            label="Salvar"
            icon="pi pi-save"
            severity="success"
            onClick={() => formik.handleSubmit()}
            loading={loadingSubmit}
            type="submit"
          />
          <Button label="Voltar" icon="pi pi-times" severity="secondary" onClick={() => setDialogAgendamentoVisible(false)} />
        </div>
      </Dialog>
      <DialogProfissional
        dialogProfissionalVisible={dialogProfissionalVisible}
        setDialogProfissionalVisible={setDialogProfissionalVisible}
        onSelectMetodo={(profissional) => {
          formik.setFieldValue("profissional", profissional);
          formik.setFieldValue("profissionalId", profissional.id);
        }}
      />
      <DialogPaciente
        dialogPacienteVisible={dialogPacienteVisible}
        setDialogPacienteVisible={setDialogPacienteVisible}
        onSelectMetodo={(paciente) => {
          formik.setFieldValue("paciente", paciente);
          formik.setFieldValue("pacienteId", paciente.id);
        }}
      />

      {permissaoPesquisaSala && (
        <DialogSala
          dialogSalaVisible={dialogSalaVisible}
          setDialogSalaVisibleVisible={setDialogSalaVisible}
          onSelectMetodo={(sala) => {
            formik.setFieldValue("sala", sala);
            formik.setFieldValue("salaId", sala.id);
          }}
        />
      )}
    </>
  );
};

export default DialogAgendamentoCadastrar;
