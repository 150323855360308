import { useMountEffect } from "primereact/hooks";
import { Messages } from "primereact/messages";
import React, { useRef } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import ProtectedRoute from "../../util/ProtectedRoute";
import UnprotectedRoute from "../../util/UnprotectedRoute";

import EmpresaUsuarioPage from "../empresa/usuario/page.component";
import Index from "../index.component";
import AgendamentoPesquisa from "../loggedIn/agendamento/pesquisa.component";
import Dashboard from "../loggedIn/dashboard.component";
import ContaPrincipal from "../loggedIn/financeiro/conta/principal.component";
import MovimentacaoEdicao from "../loggedIn/financeiro/movimentacao/edicao.component";
import MovimentacaoPesquisa from "../loggedIn/financeiro/movimentacao/pesquisa.component";
import PacienteEdicao from "../loggedIn/paciente/edicao.component";
import PacientePesquisa from "../loggedIn/paciente/pesquisa.component";
import Profile from "../loggedIn/perfil/perfil.component";
import ProfissionalEdicao from "../loggedIn/profissional/edicao.component";
import ProfissionalPesquisa from "../loggedIn/profissional/pesquisa.component";
import Login from "../user/login/login.component";
import Registrar from "../user/registro/registrar.component";

import { Toast } from "primereact/toast";
import AvisoMensagemPesquisa from "../mensagem/avisoMensagem.component";
import SalaPesquisa from "../sala/pesquisa.component";
import AlterarSenhaToken from "../user/alterarSenhaToken/principal.component";
import RecuperarConta from "../user/recuperarConta/principal.component";
import ComissaoPesquisa from "../comissao/pesquisa.component";

const Content = () => {
  const msgs = useRef(null);
  const toast = useRef(null);
  const mostrarMensagem = (message, severity, summary, isToast) => {
    if (isToast) {
      toast.current.show({ severity: severity, summary: summary, detail: message });
      return;
    }
    msgs.current.clear();
    if (message instanceof Array) {
      message.forEach((element) => {
        msgs.current.show({
          sticky: true,
          severity: severity,
          detail: element,
          closable: true
        });
      });
    } else if (typeof message !== "object") {
      msgs.current.show({
        sticky: true,
        severity: severity,
        detail: message,
        summary: summary,
        closable: true
      });
    } else {
      msgs.current.show({
        sticky: true,
        severity: "error",
        detail: "Houve um erro no servidor!",
        closable: true
      });
    }

    window.scrollTo(0, 0);
  };

  useMountEffect(() => {
    if (new URLSearchParams(window.location.search).has("welcome")) mostrarMensagem("Seja Bem Vindo!", "info");
  });

  return (
    <div style={{ margin: "auto" }}>
      <Messages ref={msgs} />
      <Toast ref={toast} />
      <BrowserRouter basename={"/"}>
        <Routes>
          <Route
            path="/"
            element={
              <UnprotectedRoute>
                <Index mostrarMensagem={mostrarMensagem} />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/registrar"
            element={
              <UnprotectedRoute>
                <Registrar mostrarMensagem={mostrarMensagem} />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              <UnprotectedRoute>
                <Login mostrarMensagem={mostrarMensagem} />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/recuperarConta"
            element={
              <UnprotectedRoute>
                <RecuperarConta mostrarMensagem={mostrarMensagem} />
              </UnprotectedRoute>
            }
          />
          <Route
            path="/alterarSenhaToken"
            element={
              <UnprotectedRoute>
                <AlterarSenhaToken mostrarMensagem={mostrarMensagem} />
              </UnprotectedRoute>
            }
          />

          <Route
            path="home"
            element={
              <ProtectedRoute>
                <Dashboard mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="perfil"
            element={
              <ProtectedRoute>
                <Profile mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="paciente"
            element={
              <ProtectedRoute permissoes={["PES_PACIENTE"]} titulo="Pesquisa de Pacientes">
                <PacientePesquisa />
              </ProtectedRoute>
            }
          />
          <Route
            path="paciente/cadastro"
            element={
              <ProtectedRoute permissoes={["PES_PACIENTE", "CAD_PACIENTE"]} titulo="Cadastro de Paciente">
                <PacienteEdicao mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="profissional"
            element={
              <ProtectedRoute permissoes={["PES_PROFISSIONAL"]} titulo="Pesquisa de Profissionais">
                <ProfissionalPesquisa />
              </ProtectedRoute>
            }
          />
          <Route
            path="profissional/cadastro"
            element={
              <ProtectedRoute permissoes={["PES_PROFISSIONAL", "CAD_PROFISSIONAL"]} titulo="Cadastro de Profissional">
                <ProfissionalEdicao mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="agendamento"
            element={
              <ProtectedRoute permissoes={["PES_AGENDAMENTO"]} titulo="Pesquisa de Agendamentos">
                <AgendamentoPesquisa mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="financeiro/conta"
            element={
              <ProtectedRoute permissoes={["ADM_FINANCEIRO_CONTA"]} titulo="Pesquisa de Contas">
                <ContaPrincipal mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="financeiro/movimentacao"
            element={
              <ProtectedRoute permissoes={["PES_FINANCEIRO_MOVIMENTACAO"]} titulo="Pesquisa de Movimentações">
                <MovimentacaoPesquisa mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="financeiro/movimentacao/cadastro"
            element={
              <ProtectedRoute
                permissoes={["PES_FINANCEIRO_MOVIMENTACAO", "CAD_FINANCEIRO_MOVIMENTACAO"]}
                titulo="Cadastro de Movimentação"
              >
                <MovimentacaoEdicao mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="empresa/usuario"
            element={
              <ProtectedRoute permissoes={["ADM_EMPRESA_USUARIO"]}>
                <EmpresaUsuarioPage mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/aviso"
            element={
              <ProtectedRoute permissoes={["PES_AVISO_MENSAGEM"]} titulo="Pesquisa de Avisos">
                <AvisoMensagemPesquisa mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sala"
            element={
              <ProtectedRoute permissoes={["PES_SALA"]} titulo="Pesquisa de Salas">
                <SalaPesquisa mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/comissao"
            element={
              <ProtectedRoute titulo="Comissão" permissoes={["ADM_SUPER"]}>
                <ComissaoPesquisa mostrarMensagem={mostrarMensagem} />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default Content;
