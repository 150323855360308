import React from "react";
// import React, { useEffect } from "react";
import "./App.css";

import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "../node_modules/primeflex/primeflex.css";

import Header from "./components/layout/header.component";
import Content from "./components/layout/content.component";

const App = () => {
  return (
    <>
      <Header />
      <Content />
    </>
  );
};

export default App;
